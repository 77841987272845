import { ADDRESS, APP_NAME, FAX, PHONE } from 'constants/app';

const locales = {
  'hipaaAndPhi.title': 'HIPAA and PHI',
  'hipaaAndPhi.text': `Certain demographic, health and/or health-related information that ${APP_NAME} collects about Users as part of providing the Services to our Healthcare Providers may be “protected health information” or “PHI” and governed by the Health Insurance Portability and Accountability Act and its implementing regulations (“HIPAA”). Specifically, when (i) ${APP_NAME} is providing administrative, operational and other services to a Health Care Provider and this Healthcare Provider is a “Covered Entity” (as such term is defined in HIPAA); and (ii) in order to provide those services, ${APP_NAME} receives identifiable information about a User on behalf of the Healthcare Provider, ${APP_NAME} is acting as a “Business Associate” (as such term is defined in HIPAA) of the Health Care Provider, and this identifiable information is regulated as PHI.
    {breakline}{breakline}
    HIPAA provides specific protections for the privacy and security of PHI and restricts how PHI is used and disclosed. <b>Please read the Notice of Privacy Practices of your Health Provider to understand how your PHI can be used and disclosed.</b>
    {breakline}{breakline}
    <b>HIPAA does not apply to Personal Data (defined below) that is not PHI.</b> Personal data that a User provides to ${APP_NAME} when ${APP_NAME} is not acting as a Business Associate is not PHI. To provide just a few examples, when you (i) create an account, (ii) search for Healthcare Providers or available appointments with Healthcare Providers, (iii) complete general medical history forms that are not required by or provided by a particular Healthcare Provider (“General Medical History Forms”); (iv) post reviews; or (v) provide device/IP Information or Web Analytics information by browsing our websites (see below).`,
  'hipaaAndPhi.personalData.title': 'Personal Data',
  'hipaaAndPhi.personalData.text': `The following subsections detail the categories of Personal Data that we collect and have collected over the past twelve (12) months. “Personal Data” means any information that identifies or relates to a particular individual and also includes information referred to as “personally identifiable information” or “personal information” under applicable data privacy laws, rules or regulations. For each category of Personal Data, these subsections also set out the source of that Personal Data, our commercial or business purpose for collecting that Personal Data and the categories of third parties with whom we share that Personal Data. More information regarding those sources and categories are set forth below.`,
  'hipaaAndPhi.covid19Data.title': 'COVID-19 Data',
  'hipaaAndPhi.covid19Data.text': `Personal Data that a User provides to ${APP_NAME} for the purpose of Covid-19 vaccine scheduling may be shared with local, state and federal public health authorities. These authorities may require or request information maintained by ${APP_NAME} in connection with your appointments to receive the Covid-19 vaccination. By using this service, you agree that ${APP_NAME} may provide any data related to your Covid-19 vaccine to government authorities and that the data sent to those authorities may contain Personal Data.`,
  'hipaaAndPhi.userPersonalData.title': 'User Personal Data',
  'hipaaAndPhi.userPersonalData.text':
    'THE FOLLOWING SUBSECTIONS APPLY ONLY TO USERS. IF YOU ARE A HEALTHCARE PROVIDER, PLEASE SEE THE HEALTHCARE PROVIDER PERSONAL DATA SECTION BELOW.',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.title': 'Categories of Personal Data We Collect',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.categoryOfPersonalData': 'Category of Personal Data',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.examplesOfPersonalDataCollected':
    'Examples of Personal Data Collected',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.source': 'Source',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.thirdPartiesWithWhomWeShareDataForBusinessPurposes':
    'Third Parties With Whom We Share Data For Business Purposes',

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.paymentInformation': 'Payment Information',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.paymentInformation.examplesOfPersonalDataCollected': [
    'Payment card type',
    'Last four digits of payment card',
    'Billing contact',
    'Billing email',
  ],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.paymentInformation.source': ['You'],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.paymentInformation.thirdPartiesWithWhomWeShareDataForBusinessPurposes':
    ['Service Providers (specifically, our current payment processing partner, Stripe, Inc. (“Stripe”)'],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.deviceIpInformation': 'Device/IP Information',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.deviceIpInformation.examplesOfPersonalDataCollected': [
    'IP address',
    'Device ID',
    'Domain server',
    'Type of device/operating system/browser used to access the Services',
  ],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.deviceIpInformation.source': ['You', 'Third Parties'],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.deviceIpInformation.thirdPartiesWithWhomWeShareDataForBusinessPurposes':
    [
      'Service Providers',
      'Analytics Partners',
      'Ad Networks',
      'Third-Party Business Partners You Access Through the Services',
    ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.webAnalytics': 'Web Analytics',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.webAnalytics.examplesOfPersonalDataCollected': [
    'Web page interactions',
    'Referring webpage/source through which you access the Services',
    'Non-identifiable request IDs',
    'Statistics associated with the interaction between device or browser and the Services',
  ],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.webAnalytics.source': ['You'],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.webAnalytics.thirdPartiesWithWhomWeShareDataForBusinessPurposes': [
    'Service Providers',
    'Analytics Partners',
    'Ad Networks',
    'Third-Party Business Partners You Access Through the Service',
  ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.geolocationData': 'Geolocation Data',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.geolocationData.examplesOfPersonalDataCollected': [
    'IP address-based location information',
  ],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.geolocationData.source': ['You'],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.geolocationData.thirdPartiesWithWhomWeShareDataForBusinessPurposes': [
    'Service Providers',
    'Analytics Partners',
    'Ad Networks',
    'Third-Party Business Partners You Access Through the Service',
  ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.otherIdentifyingInformation':
    'Other Identifying Information That You Voluntarily Choose to Provide',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.otherIdentifyingInformation.examplesOfPersonalDataCollected': [
    'Unique identifiers such as passwords',
    'Personal Data in emails, letters, or other communications you send to us',
  ],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.otherIdentifyingInformation.source': ['You'],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.otherIdentifyingInformation.thirdPartiesWithWhomWeShareDataForBusinessPurposes':
    ['Service Providers'],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.userContactData': 'User Contact Data',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.userContactData.examplesOfPersonalDataCollected': [
    'First and last name',
    'E-mail',
    'Phone number',
    'Mailing address',
  ],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.userContactData.source': ['You'],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.userContactData.thirdPartiesWithWhomWeShareDataForBusinessPurposes': [
    'Service Providers',
    'Ad Networks',
    'Healthcare Providers',
    'Insurance Providers',
    'Health Information Exchanges',
    'Parties You Authorize, Access or Authenticate',
  ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.userDemographicData': 'User Demographic Data',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.userDemographicData.examplesOfPersonalDataCollected': [
    'Gender or gender identity',
    'Age',
    'Date of birth',
    'Zip code',
    'Race',
    'Sexual orientation',
  ],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.userDemographicData.source': ['You'],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.userDemographicData.thirdPartiesWithWhomWeShareDataForBusinessPurposes':
    [
      'Service Providers',
      'Ad Networks',
      'Healthcare Providers',
      'Health Information Exchanges',
      'Parties You Authorize, Access or Authenticate',
    ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.medicalData': 'Medical Data',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.medicalData.examplesOfPersonalDataCollected': [
    'Health conditions',
    'Healthcare Providers visited',
    'Reasons for visit',
    'Dates of visit',
    'Medical history and health information you provide us',
  ],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.medicalData.source': ['You'],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.medicalData.thirdPartiesWithWhomWeShareDataForBusinessPurposes': [
    'Service Providers',
    'Ad Networks',
    'Healthcare Providers',
    'Health Information Exchanges',
    'Parties You Authorize, Access or Authenticate',
  ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.insuranceInformation': 'Insurance Information',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.insuranceInformation.examplesOfPersonalDataCollected': [
    'Insurance carrier',
    'Insurance plan',
    'Member ID',
    'Group ID',
    'Payer ID',
  ],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.insuranceInformation.source': ['You'],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.insuranceInformation.thirdPartiesWithWhomWeShareDataForBusinessPurposes':
    [
      'Service Providers',
      'Ad Networks',
      'Healthcare Providers',
      'Health Information Exchanges',
      'Parties You Authorize, Access or Authenticate',
    ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.bookingAppointmentData': 'Booking Appointment Data',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.bookingAppointmentData.examplesOfPersonalDataCollected': [
    'Appointment date/time',
    'Provider information',
    'Appointment procedure',
    'Whether or not user is a new patient for a particular provider',
  ],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.bookingAppointmentData.source': ['You', 'Third Parties'],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.bookingAppointmentData.thirdPartiesWithWhomWeShareDataForBusinessPurposes':
    ['Service Providers', 'Analytics Partners', 'Healthcare Providers', 'Health Information Exchanges'],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.socialNetworkData': 'Social Network Data',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.socialNetworkData.examplesOfPersonalDataCollected': [
    'E-mail',
    'Phone number',
    'Username',
    'IP address',
    'Device ID',
  ],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.socialNetworkData.source': ['You', 'Third Parties'],
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.socialNetworkData.thirdPartiesWithWhomWeShareDataForBusinessPurposes':
    ['Service Providers', 'Ad Networks', 'Parties You Authorize, Access or Authenticate'],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.categoriesOfSourcesOfPersonalData':
    'Categories of Sources of Personal Data',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.fromYou': 'From You',

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.provideInformationDirectlyToUs.title':
    'When You Provide Information Directly to Us',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.provideInformationDirectlyToUs.text': [
    'When you create an account or use our interactive tools and services, such as searching for Healthcare Providers or available appointments with Healthcare Providers and completing Medical History Forms prior to Healthcare Provider appointments.',
    'When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys and questionnaires, or post reviews.',
    'When you send us an email or otherwise contact us.',
  ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.personalDataIsAutomatically.title':
    'When Personal Data is Automatically Collected When You Use the Services',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.personalDataIsAutomatically.text': [
    'Through Cookies (defined below).',
    'If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices.',
    'If you download our mobile application or use a location-enabled browser, we may receive information about your location and mobile device, as applicable.',
  ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.fromThirdParties': 'From Third Parties',

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.serviceProviders.title': 'Service Providers',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.serviceProviders.text': [
    'We may use service providers to analyze how you interact and engage with the Services, or to help us provide you with customer support.',
    'We may use service providers to obtain information to generate leads and to create user profiles',
  ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.analyticsPartners.title': 'Analytics Partners',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.analyticsPartners.text': [
    'We may work with analytics partners to provide us analytics on website traffic or the usage of the Services.',
    'We use this data to optimize and market our Services.',
  ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.healthcareProviders.title': 'Healthcare Providers',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.healthcareProviders.text': [
    'We may receive certain data from your Healthcare Provider(s) to facilitate booking appointments.',
  ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.socialNetworks.title': 'Social Networks',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.socialNetworks.text': [
    'If you provide your social network account credentials to us or otherwise sign in to the Services through a third-party site or service, you understand some content and/or information in those accounts may be transmitted into your account with us.',
  ],

  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.advertisingPartners.title': 'Advertising Partners',
  'hipaaAndPhi.categoriesOfPersonalDataWeCollect.advertisingPartners.text': [
    'We receive information about you from some of our service providers who assist us with marketing or promotional services related to how you interact with our Services, advertisements or communications.',
  ],

  'hipaaAndPhi.controllingYourPersonalDataNotifications.title': 'Controlling Your Personal Data & Notifications',
  'hipaaAndPhi.controllingYourPersonalDataNotifications.text': `If you are a registered user of the Services, you can modify certain Personal Data or account information by logging in and accessing your account. If you wish to close your account, please email us at {email}. ${APP_NAME} will use reasonable efforts to delete your account as soon as reasonably possible. Please note, however, that ${APP_NAME} reserves the right to retain information from closed accounts consistent with our internal data retention policies and procedures.
  {breakline}{breakline}
  You must promptly notify us if any of your account data is lost, stolen, or used without permission.`,

  'hipaaAndPhi.californiaRights.title': 'California Rights',
  'hipaaAndPhi.californiaRights.text': `The California Consumer Privacy Act of 2018 (“CCPA”) provides California residents with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights. If you have any questions about this section or whether any of the following applies to you, please contact us at {email} and indicate “California Rights” in the subject line of your communication.`,

  'hipaaAndPhi.contactInformation.title': 'Contact Information',
  'hipaaAndPhi.contactInformation.text': `If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data, your choices and rights regarding such use, please do not hesitate to contact us at:`,
  'hipaaAndPhi.contactInformation.email': 'Email: {email}',
  'hipaaAndPhi.contactInformation.address': `Address: ${ADDRESS}`,
  'hipaaAndPhi.contactInformation.phone': `Phone: ${PHONE}`,
  'hipaaAndPhi.contactInformation.fax': `Fax: ${FAX}`,
};

export default locales;

const locales = {
  'bookAppointment.page.title': 'Book an appointment',
  'bookAppointment.page.timer.message': 'Your booking will be active within {minutes} minutes',

  'bookAppointment.generalInfo.title': 'General information',
  'bookAppointment.note.label': `Comment for the doctor's office (optional)`,
  'bookAppointment.duration.label': 'Appointment duration',
  'bookAppointment.price.label': 'Appointment price',
  'bookAppointment.phoneNumber.label': 'Phone number',
  'bookAppointment.symptom.label': 'Symptom',
  'bookAppointment.specialities.label': 'Speciality',
  'bookAppointment.appointmentTime.label': 'Appointment time',
  'bookAppointment.duration.placeholder': '{minutes} minutes',
  'bookAppointment.cancellationFees.title': 'Cancellation fee',
  'bookAppointment.cancellationFees.threeDays': '72 hours or earlier',
  'bookAppointment.cancellationFees.twoDays': '48 hours',
  'bookAppointment.cancellationFees.oneDay': '24 hours before',
  'bookAppointment.cancellationFees.oneHour': '1 hour before',
  'bookAppointment.questionnaire.title': 'Questionnaire',
  'bookAppointment.questionnaire.whatBrings.label': 'What brings you here today?',
  'bookAppointment.questionnaire.discussAbout.label': 'What would you like to discuss with your health care provider?',
  'bookAppointment.questionnaire.symptomsDescriptive.label': 'If symptoms exist please be descriptive',
  'bookAppointment.questionnaire.temperature.label': 'Temperature',
  'bookAppointment.questionnaire.bloodPressure.label': 'Blood pressure',
  'bookAppointment.questionnaire.heartRate.label': 'Heart rate',
  'bookAppointment.questionnaire.respiratoryRate.label': 'Respiratory rate',
  'bookAppointment.questionnaire.age.label': 'Age',
  'bookAppointment.questionnaire.birthDate.label': 'Birth date',
  'bookAppointment.questionnaire.maritalStatus.label': 'Marital status',
  'bookAppointment.questionnaire.genderIdentity.label': 'Gender identity',
  'bookAppointment.questionnaire.sex.label': 'Sex',
  'bookAppointment.questionnaire.ethnicity.label': 'Ethnicity',
  'bookAppointment.questionnaire.medications.label': 'Medications',
  'bookAppointment.questionnaire.whatMedications.label': 'What medications, if any, do you currently take?',
  'bookAppointment.questionnaire.temperature.preview.label': '{temperature} ℉',
  'bookAppointment.questionnaire.bloodPressure.preview.label': '{bloodPressure} mmHg',
  'bookAppointment.questionnaire.heartRate.preview.label': '{heartRate} beats per minute',
  'bookAppointment.questionnaire.respiratoryRate.preview.label': '{respiratoryRate} breaths per minute',
  'bookAppointment.payment.title': 'Payment method',
  'bookAppointment.confirmModal.discard.title': 'Discard an appointent',
  'bookAppointment.confirmModal.message.title': 'Are you sure you want to cancel your appointment?',
  'bookAppointment.confirmModal.discard.button': 'Discard an appointent',
  'bookAppointment.confirmModal.cancel.button': 'Cancel',
};

export default locales;

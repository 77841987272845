const locales = {
  'patients.title': 'Patients',

  'patientProfile.title': 'Patient profile',
  'patientProfile.genderAndAge': '{gender}, {age, number} {age, plural, one {year} other {years}} old',
  'patientProfile.tab': 'Patient profile',
  'patientProfile.birthDate': 'Birth date',
  'patientProfile.phoneNumber': 'Phone number',
  'patientProfile.state': 'State: {state}',
  'patientProfile.address': 'Address: {address}',
  'patientProfile.zipCode': 'Zip code: {zipCode}',
  'patientProfile.location': 'Location',
  'patientProfile.maritalStatus': 'Marital status',
  'patientProfile.languages': 'Languages',
  'patientProfile.email': 'Email',
  'patientProfile.ethnicity': 'Ethnicity',
  'patientProfile.genderIdentity': 'Gender identity',
  'patientProfile.timeZone': 'Time zone',
  'patientProfile.aboutMe': 'About me',
  'patientProfile.patient': 'Patient',

  'patientProfile.healthProfile.tab': 'Health profile',
  'patientProfile.healthProfile.empty': 'Patient has not filled his health profile yet',

  'patientProfile.appointments.tab': 'Appointments',
  'patientProfile.appointments.empty': 'Patient has not filled his appointmets yet',

  'patientProfile.assessmentAndPrescription.tab': 'Assessment & Prescription',
  'patientProfile.assessments.empty': 'Patient has not filled his assessments yet',
  'patientProfile.speciality.cell': 'Speciality',
  'patientProfile.dateAndTime.cell': 'Date & Time',
  'patientProfile.diagnosis.cell': 'Diagnosis',
};

export default locales;

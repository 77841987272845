const locales = {
  'doctors.tab.personalInformation': 'Personal information',

  'doctors.tab.workingHours': 'Working hours',

  'doctors.tab.medicalLicense': 'Medical title and license',
  'doctors.tab.medicalLicense.boardLicenseType': 'Board License Type',

  'doctors.tab.cancelationPolicy': 'Cancelation fee',
  'doctors.tab.cancelationPolicy.72Hours': '72 hours or earlier',
  'doctors.tab.cancelationPolicy.48Hours': '48 hours',
  'doctors.tab.cancelationPolicy.24Hours': '24 hours before',
  'doctors.tab.cancelationPolicy.1Hour': '1 hour before',
};

export default locales;

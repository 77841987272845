const locales = {
  'videoConference.cameraAudioWarningModal.title': 'Can’t find your camera and microphone',
  'videoConference.cameraAudioWarningModal.body':
    'Please make sure that your camera and microphone is available. Check your settings and refresh the page.',
  'videoConference.cameraWarningModal.title': 'Can’t find your camera',
  'videoConference.cameraWarningModal.body':
    'Please make sure that your camera is available. Check your settings and refresh the page.',
  'videoConference.microphoneWarningModal.title': 'Can’t find your microphone',
  'videoConference.microphoneWarningModal.body':
    'Please make sure that your microphone is available. Check your settings and refresh the page.',
  'videoConference.leave.button': 'Leave',
  'videoConference.join.button': 'Join',
  'videoConference.settings.tittle': 'Settings',
  'videoConference.settings.camera.label': 'Camera',
  'videoConference.settings.microphone.label': 'Microphone',
  'videoConference.settings.notification': 'Settings were updated',

  'videoConference.duplicatedIdentityModal.title': 'You were disconnected',
  'videoConference.duplicatedIdentityModal.bodyText': 'You joined this call on another device or tab.',
  'videoConference.expireOrNotStarted.title': 'Unable to connect to video call.',
  'videoConference.expireOrNotStarted.bodyText':
    'Video call server is currently unavailable. Please try again later or contact technical support if the problem persists.',
  'videoConference.waiting': 'Waiting for the participant to join',
  'videoConference.chat.sidebar.title': 'Chat',
  'videoConference.questionnaire.sidebar.title': 'Questionnaire',
  'videoConference.assessment.sidebar.title': 'New assessment',
  'videoConference.warning.leaveVideo.title': 'Leave video consultation',
  'videoConference.warning.leaveVideo.description': 'Are you sure you want to leave this consultation?',
  'videoConference.warning.leaveVideo.submit': 'Leave consultation',
  'videoConference.reconnection.title': 'Internet connection has been lost.',
  'videoConference.reconnection.describe': 'Trying to connect…',
  'videoConference.youLostConnection.title': 'You lost your internet connection.',
};
export default locales;

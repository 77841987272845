import { APP_NAME } from 'constants/app';

const locales = {
  'auth.chooseRightRole': 'Choose the right role for you to register',
  'auth.asDoctor': 'As a doctor',
  'auth.areYouDoctor': `Are you a doctor? Do you want to help people with their health issues and find more patients? Create your provider account and ${APP_NAME} helps you to reach more patients!`,
  'auth.asPatient': 'As a patient',
  'auth.areYouSearchingForHighClassMedicalHelp': `Are you searching for high-class medical help? Create your patient account and ${APP_NAME} doctors will take care of you! Receive professional medical help anywhere you are by phone or video.`,
  'auth.strivingToProvideIncreasingAccess': 'Striving to provide increasing access to evidence based',
  'auth.keptHonestThroughValueBasedQualityMeasures':
    'Kept honest through value based quality measures, all while lowering patient costs and burden, and appropriately paying physicians.',
  'auth.joinUsInSocialNetworks': 'Join us in social networks',
  'auth.header.alreadyHaveAnAccount': 'Already have an account?',
  'auth.footer.links': `By signing up, I agree to ${APP_NAME} {terms} and {policy}.`,
  'auth.loginToYourAccount': `Login to your account`,
  'auth.thanksForSigningUp': `Thanks for signing up!`,
  'auth.yourAccountHasBeenCreated': `Your account has been created, you can log in with the following credentials {break} after you have activated your account.`,
  'auth.emailConfirmationLinkHasBeenResent': 'Email confirmation link has been resent',
  'auth.emailConfirmationLinkHasExpired': 'Email confirmation link has expired',
  'auth.yourEmailConfirmationLinkHasExpiredClickResend':
    'Unfortunately, your email confirmation link has expired. {break}You can also click the Resend Link button below to have another email sent to you.',
  'auth.yourEmailConfirmationLinkHasExpiredGoToAccountSettings':
    'Unfortunately, your email confirmation link has expired. {break}In case you didn\'t verify your email, you could send a verification link in “Account settings".',
  'auth.emailTokenIsInvalid': 'The email token is invalid',
  'auth.header.didNotRegisterYet': 'Did not register yet?',
  'auth.pleaseEnterEmailWillSendRecoveryLink':
    'Please enter the email address you used when creating your account and we will send you a password recovery link.',
  'auth.weSentPasswordRecoveryLinkToEmailAddress': 'We sent a password recovery link to your email address:',
  'auth.didNotReceiveEmail': `Didn't receive the email? Check your junk mail or request another one.`,
  'auth.youNeedToSetNewPasswordForYourAccount': 'You need to set a new password for your account.',
  'auth.passwordRecoveryLinkHasExpired': 'Password recovery link has expired',
  'auth.unfortunatelyPasswordRecoveryLinkHasExpired':
    'Unfortunately, your password recovery link has expired. Reset password and keep in mind that you have 24 hours to follow the link from your email.',
  'auth.yourEmailVerifiedPleaseLogInToSystem': `Your email verified please log in to system`,
};

export default locales;

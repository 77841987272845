const locales = {
  'assessments.title': 'Assessments',
  'assessments.thereAreNoAssessmentsYet': 'There are no assessments yet.',
  'assessments.general': 'General',
  'assessments.orders': 'Orders',
  'assessments.orders.prescriptionMedication': 'Prescription Medication',
  'assessments.orders.imaging': 'Imaging',
  'assessments.orders.bloodWork': 'Blood Work',
  'assessments.orders.biopsy': 'Biopsy',
  'assessments.orders.therapy': 'Therapy',
  'assessments.orders.medicalEquipment': 'Medical Equipment',
  'assessments.orders.sickNote': 'Sick Note',
  'assessments.allAssessment': 'All assessment',

  'assessment.title': 'All assessment - prescription',
  'assessment.appointmentStartTime': 'Appointment start time:',
  'assessment.patientName': 'Patient Name:',
  'assessment.patientLastName': 'Patient Last Name:',
  'assessment.birthDate': 'Birth date:',
  'assessment.email': 'Email:',
  'assessment.general': 'General',
  'assessment.subjective': 'Subjective:',
  'assessment.objective': 'Objective:',
  'assessment.assessment': 'Assessment:',
  'assessment.plan': 'Plan:',
  'assessment.diagnose': 'Diagnose:',
  'assessment.orders': 'Orders',
  'assessment.prescriptionMedication': 'Prescription Medication',
  'assessment.drugName': 'Drug name:',
  'assessment.doseStrength': 'Dose Strength:',
  'assessment.dosageForm': 'Dosage Form:',
  'assessment.quantityPrescribed': 'Quantity prescribed:',
  'assessment.directionsForUse': 'Directions for use:',
  'assessment.numberOfRefills': 'Number of refills:',
  'assessment.imaging.title': 'Imaging',
  'assessment.imagingModality': 'Imaging Modality:',
  'assessment.directionsForPatientPreparations': 'Directions for patient preparations:',
  'assessment.bloodWork.title': 'Blood work',
  'assessment.bloodWorkBeingOrdered': 'Blood work being ordered:',
  'assessment.biopsy.title': 'Biopsy',
  'assessment.describeLesionBeingBiopsied': 'Describe lesion being biopsied:',
  'assessment.therapy.title': 'Therapy',
  'assessment.physical.title': 'Physical',
  'assessment.occupational.title': 'Occupational',
  'assessment.lengthInTimesPerWeekPerMonth': 'Length in times per week per month:',
  'assessment.other': 'Other:',
  'assessment.medicalEquipment.title': 'Medical Equipment',
  'assessment.freeTextEquipmentNeeded': 'Free text equipment needed:',
  'assessment.directionsForPatientUse': 'Directions for patient use:',
  'assessment.sickNote.title': 'Sick note',
  'assessment.doctorsName': 'Doctors Name:',
  'assessment.doctorsLastName': 'Doctors Last Name:',
  'assessment.city': 'City:',
  'assessment.streetAddress': 'Street Address:',
  'assessment.state': 'State:',
  'assessment.zipCode': 'ZIP Code:',
  'assessment.nationalProviderIdentifier': 'National Provider Identifier (NPI):',
  'assessment.signature': 'Signature:',

  'assessment.modal.title': 'Assessment & Prescription',
  'assessment.modal.hidePrescription': 'Hide prescription',
  'assessment.modal.showPrescription': 'Show prescription',
};

export default locales;

import { when, map, of, is, isNil, always, mergeAll } from 'ramda';

type MessageConfig = { [key: string]: string };

const loadIntlMessages = (locale?: string, namespaces?: string | string[]): MessageConfig => {
  // eslint-disable-next-line eslint-comments/disable-enable-pair
  /* eslint-disable import/no-dynamic-require -- needed to load locale messages, can be replaced with import() */
  // eslint-disable-next-line eslint-comments/disable-enable-pair
  /* eslint-disable global-require -- needed to load locale messages, can be replaced with import() */
  // eslint-disable-next-line eslint-comments/disable-enable-pair
  /* eslint-disable @typescript-eslint/no-var-requires -- needed to load locale messages, can be replaced with import() */
  const sharedLocales = require(`../locales/shared/${locale}.ts`).default;

  const namespaceArray = when(isNil, always([]))(namespaces);

  const namespaceLocales = map(
    (namespace) => require(`../locales/${namespace}/${locale}.ts`).default,
    when(is(String), of, namespaceArray),
  );

  return mergeAll([sharedLocales, ...namespaceLocales]);
};

export default loadIntlMessages;

import { APP_NAME } from 'constants/app';

const locales = {
  'landing.title': 'Home',
  'landing.letsGo': "Let's go",
  'landing.bestDoctor': 'best doctor',
  'landing.weHelpToFindTheBestDoctorNearYou': 'We help to find the {bestDoctor} near you',
  'landing.simpleText': `We are ${APP_NAME}! We help you to find general specialists and the closest doctors to handle your health problems`,

  'landing.letsGoInTouch': "Let's get in touch",
  'landing.doYouHaveAnyQuestions': 'Do you have any questions?',

  'landing.ourDoctors': 'Our doctors',
  'landing.ourDoctors.title': 'Our high qualited doctors',
  'landing.ourDoctors.seeMoreDoctors': 'See more doctors',
  'landing.ourDoctors.gender': 'Gender: {gender}',
  'landing.ourDoctors.patients': '+{count, number} {count, plural, one {patient} other {patients}}',

  'landing.ourPatientsAreSatisfiedWithTheFacilitiesWeHave': 'Our patients are satisfied with the facilities we have',
  'landing.callCenter': '{hours} Call center',

  'landing.ourFacilities': 'Our facilities',
  'landing.ourFacilities.title': 'Personal service & Good attempt',
  'landing.ourFacilities.text': `To make your treatment feel comfortable and according to what you expect, we provide the following facilities:`,
  'landing.ourFacilities.item_1': 'Up-to-date diagnostic and operating equipment',
  'landing.ourFacilities.item_2': 'Laboratory and Pharmacy',
  'landing.ourFacilities.item_3': 'Systematic and well-structured appointments with doctors',
  'landing.ourFacilities.item_4': "Easily find the primary care provider (PCP) who's right for you",
  'landing.ourFacilities.item_5': 'Stay on top of your physical and mental health—because every piece of you matters',
  'landing.ourFacilities.item_6': 'Get referrals to specialists and in-person care whenever needed',
  'landing.ourFacilities.item_7': 'Know what you need to do to keep your health on track',

  'landing.weAreHereToHelp': "We're here to help",
  'landing.weAreHereToHelp.title': 'The right care when you need it most',
  'landing.weAreHereToHelp.text_1': 'From everyday to complex care, we’ve got you covered.',
  'landing.weAreHereToHelp.text_2': 'Connect with a doctor, therapist, specialist or dietitian by phone, video.',
  'landing.weAreHereToHelp.item_1': 'Browse providers who take your insurance',
  'landing.weAreHereToHelp.item_2': 'Read reviews from users',
  'landing.weAreHereToHelp.item_3': 'Book an appointment today, online',
  'landing.weAreHereToHelp.seeSpecialist': 'See specialist',
  'landing.weAreHereToHelp.seeProviders': 'See providers',
  'landing.weAreHereToHelp.seeAvailability': 'See availability',

  'landing.howItWorks': 'How it works?',
  'landing.howItWorks.title': 'Plan your appointment now',
  'landing.howItWorks.item_1.title': 'Find a local {breakline} provider',
  'landing.howItWorks.item_1.text': 'You can search for your conditions and types of providers by location',
  'landing.howItWorks.item_2.title': 'Choose your {breakline} schedule',
  'landing.howItWorks.item_2.text': 'Select your preferred date and time, and make a booking online in just a minute',
  'landing.howItWorks.item_3.title': 'Make a {breakline} payment',
  'landing.howItWorks.item_3.text': 'Pay for the booking through our secured payment to secure your appointment',
};

export default locales;

import { ADDRESS, APP_NAME } from 'constants/app';

const locales = {
  'termsOfUse.title': 'Terms of Use',
  'termsOfUse.lastUpdated': 'Last Updated: {date}',

  'termsOfUse.websiteTermsOfUse.title': 'Website Terms of Use',
  'termsOfUse.websiteTermsOfUse.text': `Version 1.0
  {breakline}{breakline}
  The <b>${APP_NAME}</b> website located at airpmd.com is a copyrighted work belonging to Air. Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features.
  {breakline}{breakline}
  All such additional terms, guidelines, and rules are incorporated by reference into these Terms.
  {breakline}{breakline}
  These Terms of Use described the legally binding terms and conditions that oversee your use of the Site. BY LOGGING INTO THE SITE, YOU ARE BEING COMPLIANT THAT THESE TERMS and you represent that you have the authority and capacity to enter into these Terms. YOU SHOULD BE AT LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF YOU DISAGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT LOG INTO AND/OR USE THE SITE.
  {breakline}{breakline}
  These terms require the use of arbitration Section 10.2 on an individual basis to resolve disputes and also limit the remedies available to you in the event of a dispute. These Terms of Use were created with the help of the Terms Of Use Generator.`,
  'termsOfUse.accessToTheSite.title': 'Access to the Site',
  'termsOfUse.accessToTheSite.text': `<b>Subject to these Terms.</b> The company grants you a non-transferable, non-exclusive, revocable, limited license to access the Site solely for your own personal, noncommercial use.
  {breakline}{breakline}
  <b>Certain Restrictions.</b> The rights approved to you in these Terms are subject to the following restrictions: (a) you shall not sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site; (b) you shall not change, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms.  All copyright and other proprietary notices on the Site must be retained on all copies thereof.
  {breakline}{breakline}
  The company reserves the right to change, suspend, or cease the Site with or without notice to you.  You approved that Company will not be held liable to you or any third-party for any change, interruption, or termination of the Site or any part.
  {breakline}{breakline}
  <b>No Support or Maintenance.</b> You agree that Company will have no obligation to provide you with any support in connection with the Site.
  {breakline}{breakline}
  Excluding any User Content that you may provide, you are aware that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Site and its content are owned by Company or Company’s suppliers. Note that these Terms and access to the Site do not give you any rights, title, or interest in or to any intellectual property rights, except for the limited access rights expressed in Section 2.1. Company and its suppliers' reserve all rights not granted in these Terms.`,
  'termsOfUse.thirdPartyLinksAdsOtherUsers.title': 'Third-Party Links & Ads; Other Users',
  'termsOfUse.thirdPartyLinksAdsOtherUsers.text': `<b>Third-Party Links & Ads.</b> The Site may contain links to third-party websites and services, and/or display advertisements for third parties.  Such Third-Party Links & Ads are not under the control of the Company, and Company is not responsible for any Third-Party Links & Ads.  The company provides access to these Third-Party Links & Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links & Ads.  You use all Third-Party Links & Ads at your own risk and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links & Ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices.
  {breakline}{breakline}
  <b>Other Users.</b> Each Site user is solely responsible for any and all of its own User Content.  Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others.  You agree that Company will not be responsible for any loss or damage incurred as the result of any such interactions.  If there is a dispute between you and any Site user, we are under no obligation to become involved.
  {breakline}{breakline}
  You hereby release and forever discharge the Company and our officers, employees, agents, successors, and assigns from, and hereby waive and relinquish, each and every past, present, and future dispute, claim, controversy, demand, right, obligation, liability, action, and cause of action of every kind and nature, that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to the Site. If you are a California resident, you hereby waive California civil code section 1542 in connection with the foregoing, which states: "a general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which if known by him or she must have materially affected his or her settlement with the debtor."
  {breakline}{breakline}
  <b>Cookies and Web Beacons.</b> Like any other website, airpmd@.com uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.`,
  'termsOfUse.disclaimers.title': 'Disclaimers',
  'termsOfUse.disclaimers.text': `The site is provided on an "as-is" and "as available" basis, and the company and our suppliers expressly disclaim any and all warranties and conditions of any kind, whether express, implied, or statutory, including all warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement.  We and our suppliers make not guarantee that the site will meet your requirements, will be available on an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe.  If applicable law requires any warranties with respect to the site, all such warranties are limited in duration to ninety (90) days from the date of first use.
  {breakline}{breakline}
  Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you.  Some jurisdictions do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you.`,
  'termsOfUse.limitationOnLiability.title': 'Limitation on Liability',
  'termsOfUse.limitationOnLiability.text': `To the maximum extent permitted by law, in no event shall Company or our suppliers be liable to you or any third-party for any lost profits, lost data, costs of procurement of substitute products, or any indirect, consequential, exemplary, incidental, special, or punitive damages arising from or relating to these terms or your use of, or incapability to use the site even if the company has been advised of the possibility of such damages.  Access to and use of the site is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system, or loss of data resulting therefrom.
  {breakline}{breakline}
  To the maximum extent permitted by law, notwithstanding anything to the contrary contained herein, our liability to you for any damages arising from or related to this agreement, will at all times be limited to a maximum of fifty U.S. dollars (u.s. $50). The existence of more than one claim will not enlarge this limit.  You agree that our suppliers will have no liability of any kind arising from or relating to this agreement.
  {breakline}{breakline}
  Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you.
  {breakline}{breakline}
  <b>Term and Termination.</b> Subject to this Section, these Terms will remain in full force and effect while you use the Site.  We may suspend or terminate your rights to use the Site at any time for any reason at our sole discretion, including for any use of the Site in violation of these Terms.  Upon termination of your rights under these Terms, your Account and right to access and use the Site will terminate immediately.  You understand that any termination of your Account may involve the deletion of your User Content associated with your Account from our live databases.  The company will not have any liability whatsoever to you for any termination of your rights under these Terms.  Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections 2 through 2.5, Section 3, and Sections 4 through 10.`,
  'termsOfUse.copyrightPolicy.title': 'Copyright Policy',
  'termsOfUse.copyrightPolicy.text': `The company respects the intellectual property of others and asks that users of our Site do the same.  In connection with our Site, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination of users of our online Site who are repeated infringers of intellectual property rights, including copyrights.  If you believe that one of our users is, through the use of our Site, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright Agent:
  {copyrightAgent}
  Please note that pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact in a written notification automatically subjects the complaining party to liability for any damages, costs, and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement.`,
  'termsOfUse.copyrightPolicy.copyrightAgent': [
    'your physical or electronic signature;',
    'identification of the copyrighted work(s) that you claim to have been infringed;',
    'identification of the material on our services that you claim is infringing and that you request us to remove;',
    'sufficient information to permit us to locate such material;',
    'your address, telephone number, and e-mail address;',
    'a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and',
    'a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.',
  ],
  'termsOfUse.general.title': 'General',
  'termsOfUse.general.text': `
  These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us and/or by prominently posting notice of the changes on our Site.  You are responsible for providing us with your most current e-mail address.  In the event that the last e-mail address that you have provided us is not valid our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice.  Any changes to these Terms will be effective upon the earliest of thirty (30) calendar days following our dispatch of an e-mail notice to you or thirty (30) calendar days following our posting of notice of the changes on our Site.  These changes will be effective immediately for new users of our Site.  Continued use of our Site following notice of such changes shall indicate your acknowledgment of such changes and agreement to be bound by the terms and conditions of such changes. Dispute Resolution. Please read this Arbitration Agreement carefully. It is part of your contract with the Company and affects your rights.  It contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.
  {breakline}{breakline}
  <b>Applicability of Arbitration Agreement.</b> All claims and disputes in connection with the Terms or the use of any product or service provided by the Company that cannot be resolved informally or in small claims court shall be resolved by binding arbitration on an individual basis under the terms of this Arbitration Agreement.  Unless otherwise agreed to, all arbitration proceedings shall be held in English.  This Arbitration Agreement applies to you and the Company, and to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or goods provided under the Terms.
  {breakline}{breakline}
  <b>Notice Requirement and Informal Dispute Resolution.</b> Before either party may seek arbitration, the party must first send to the other party a written Notice of Dispute describing the nature and basis of the claim or dispute and the requested relief.  A Notice to the Company should be sent to ${APP_NAME}. After the Notice is received, you and the Company may attempt to resolve the claim or dispute informally.  If you and the Company do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding.  The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award to which either party is entitled.
  {breakline}{breakline}
  <b>Arbitration Rules.</b> The arbitration shall be initiated through the American Arbitration Association, an established alternative dispute resolution provider that offers arbitration as set forth in this section.  If AAA is not available to arbitrate, the parties shall agree to select an alternative ADR Provider.  The rules of the ADR Provider shall govern all aspects of the arbitration except to the extent such rules are in conflict with the Terms.  The AAA Consumer Arbitration Rules governing the arbitration are available online at American Arbitration Association | ADR.org  or by calling the AAA at 1-800-778-7879.  The arbitration shall be conducted by a single, neutral arbitrator.  Any claims or disputes where the total amount of the award sought is less than Ten Thousand U.S. Dollars (US $10,000.00) may be resolved through binding non-appearance-based arbitration, at the option of the party seeking relief.  For claims or disputes where the total amount of the award sought is Ten Thousand U.S. Dollars (the US $10,000.00) or more, the right to a hearing will be determined by the Arbitration Rules.  Any hearing will be held in a location within 100 miles of your residence unless you reside outside of the United States, and unless the parties agree otherwise.  If you reside outside of the U.S., the arbitrator shall give the parties reasonable notice of the date, time, and place of any oral hearings. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.  If the arbitrator grants you an award that is greater than the last settlement offer that the Company made to you prior to the initiation of arbitration, the Company will pay you the greater of the award or $2,500.00.  Each party shall bear its own costs and disbursements arising out of the arbitration and shall pay an equal share of the fees and costs of the ADR Provider.
  {breakline}{breakline}
  <b>Additional Rules for Non-Appearance Based Arbitration.</b> If non-appearance-based arbitration is elected, the arbitration shall be conducted by telephone, online, and/or based solely on written submissions; the specific manner shall be chosen by the party initiating the arbitration.  The arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise agreed by the parties.
  {breakline}{breakline}
  <b>Time Limits.</b> If you or the Company pursues arbitration, the arbitration action must be initiated and/or demanded within the statute of limitations and within any deadline imposed under the AAA Rules for the pertinent claim.
  {breakline}{breakline}
  <b>Authority of Arbitrator.</b> If arbitration is initiated, the arbitrator will decide the rights and liabilities of you and the Company, and the dispute will not be consolidated with any other matters or joined with any other cases or parties.  The arbitrator shall have the authority to grant motions dispositive of all or part of any claim.  The arbitrator shall have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the AAA Rules, and the Terms.  The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based.  The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have.  The award of the arbitrator is final and binding upon you and the Company.
  {breakline}{breakline}
  <b>Waiver of Jury Trial.</b> THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement.  Arbitration procedures are typically more limited, more efficient, and less expensive than rules applicable in a court and are subject to very limited review by a court.  In the event, any litigation should arise between you and the Company in any state or federal court in a suit to vacate or enforce an arbitration award or otherwise, YOU AND THE COMPANY waives ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute is resolved by a judge.
  {breakline}{breakline}
  <b>Waiver of Class or Consolidated Actions.</b> All claims and disputes within the scope of this arbitration agreement must be arbitrated or litigated on an individual basis and not on a class basis, and claims of more than one customer or user cannot be arbitrated or litigated jointly or consolidated with those of any other customer or user.
  {breakline}{breakline}
  <b>Confidentiality.</b> All aspects of the arbitration proceeding shall be strictly confidential.  The parties agree to maintain confidentiality unless otherwise required by law.  This paragraph shall not prevent a party from submitting to a court of law any information necessary to enforce this Agreement, to enforce an arbitration award, or to seek injunctive or equitable relief.
  {breakline}{breakline}
  <b>Severability.</b> If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable by a court of competent jurisdiction, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Agreement shall continue in full force and effect.
  {breakline}{breakline}
  <b>Right to Waive.</b> Any or all of the rights and limitations set forth in this Arbitration Agreement may be waived by the party against whom the claim is asserted.  Such waiver shall not waive or affect any other portion of this Arbitration Agreement.
  {breakline}{breakline}
  <b>Survival of Agreement.</b> This Arbitration Agreement will survive the termination of your relationship with the Company.
  {breakline}{breakline}
  <b>Small Claims Court.</b> Nonetheless the foregoing, either you or the Company may bring an individual action in small claims court.
  {breakline}{breakline}
  <b>Emergency Equitable Relief.</b> Anyhow the foregoing, either party may seek emergency equitable relief before a state or federal court in order to maintain the status quo pending arbitration.  A request for interim measures shall not be deemed a waiver of any other rights or obligations under this Arbitration Agreement.
  {breakline}{breakline}
  <b>Claims Not Subject to Arbitration.</b> Notwithstanding the foregoing, claims of defamation, violation of the Computer Fraud and Abuse Act, and infringement or misappropriation of the other party’s patent, copyright, trademark or trade secrets shall not be subject to this Arbitration Agreement.
  {breakline}{breakline}
  In any circumstances where the foregoing Arbitration Agreement permits the parties to litigate in court, the parties hereby agree to submit to the personal jurisdiction of the courts located within Netherlands County, California, for such purposes.
  {breakline}{breakline}
  The Site may be subject to U.S. export control laws and may be subject to export or import regulations in other countries. You agree not to export, re-export, or transfer, directly or indirectly, any U.S. technical data acquired from Company, or any products utilizing such data, in violation of the United States export laws or regulations.
  {breakline}{breakline}
  Company is located at the address in Section 10.8. If you are a California resident, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Product of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.
  {breakline}{breakline}
  <b>Electronic Communications.</b> The communications between you and Company use electronic means, whether you use the Site or send us emails, or whether the Company posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Company in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Company provides to you electronically satisfy any legal obligation that such communications would satisfy if it were in a hard copywriting.
  {breakline}{breakline}
  <b>Entire Terms.</b> These Terms constitute the entire agreement between you and us regarding the use of the Site. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. The word "including" means "including without limitation". If any provision of these Terms is held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.  Your relationship to Company is that of an independent contractor, and neither party is an agent or partner of the other.  These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Company’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void.  The company may freely assign these Terms.  The terms and conditions set forth in these Terms shall be binding upon assignees.
  {breakline}{breakline}
  <b>Your Privacy.</b> Please read our Privacy Policy.
  {breakline}{breakline}
  <b>Copyright/Trademark Information.</b> Copyright ©. All rights reserved.  All trademarks, logos, and service marks displayed on the Site are our property or the property of other third parties. You are not permitted to use these Marks without our prior written consent or the consent of such a third party that may own the Marks.`,
  'termsOfUse.contactInformation.title': 'Contact Information',
  'termsOfUse.contactInformation.email': 'Email: {email}',
  'termsOfUse.contactInformation.address': `Address: ${ADDRESS}`,

  'termsOfUse.applicability.title': 'Applicability, scope, etc.',
  'termsOfUse.applicability.text': `These terms and conditions apply to the agreement between KRY International AB, org.nr. 556967-0820, ("KRY International") and the customer/patient who registers as an Account holder (the “Account Holder”) regarding KRY International’s provision of the account (the “Account”) and related services on the technical platform for Account Holders and healthcare providers (“Healthcare Provider”) that KRY International provides in the application for iOS and Android (the “App”) as well as on the website www.kry.se.
    {breakline}{breakline}
    Through the App, individuals can book appointments with licensed healthcare practitioners, in particular doctors, for medical care and advice through video meetings. The Account Holder can also submit a written description of the patient’s symptoms to the individual Account prior to the meeting. When the video meeting begins, the Account Holder can describe his/her condition and concerns to the doctor, who will then perform a visual examination of the patient, diagnose where possible, and suggest further treatment.
    {breakline}{breakline}
    KRY International is only an intermediary service between the Account Holder and Healthcare Provider and should therefore not be regarded as a Healthcare Provider. The agreement between the Account Holder and KRY International is therefore strictly a service agreement pertaining to the provision of a technical solution. KRY International is therefore not responsible for the nature or quality of medical examinations or other medical services provided within the context of a video meeting.
    {breakline}{breakline}
    The Account Holder enters into an agreement with KRY International by creating an Account in accordance with current guidelines in the App. By registering an Account in the App, the Account Holder agrees to the terms and conditions of use outlined here.
    {breakline}{breakline}
    The Account Holder is responsible for ensuring that all personal data provided when entering into agreement with KRY International is relevant, correct, and updated throughout the term of agreement. Any potential changes to this information must be reported to KRY International without delay, by e-mailing support@kry.se or calling customer service at +46 8 22 77 07.
    {breakline}{breakline}
    These terms and conditions of use, as well as instructions, policies and additional information related to the service is available in the App.
    {breakline}{breakline}
    The following provisions are not applicable if Account Holder will be provided services through the App as part of, e.g. subscriptions, employment health programme, such as part of compensation and benefit-schemes:
    {breakline}
    6.1 and 6.2 (cancellation of video meeting with right to refund)
    {breakline}
    Second sentence in 9.2 (right to terminate the agreement with a right to a refund)
    {breakline}
    Third sentence in 10.1 (force majeure with a right to a refund)`,

  'termsOfUse.accountsAndApp.title': 'Accounts and the App',
  'termsOfUse.accountsAndApp.text': `Signing of agreement, registering an Account and signing into the App require that the patient has a BankID and is sixteen (16) years of age or older to identify him/herself.
    {breakline}{breakline}
    The Account Holder is responsible for all actions relating to the use of the Account. The Account may only be used by the user to which the Account is registered and may not be utilised by, or transferred to, another person. The only exception to this rule pertains to legal guardians who may use their own Account on behalf of a child, provided that the child is below the age of eighteen (18). KRY International does not take responsibility for any misuse of Accounts by external parties or any consequences or potential damages that may result from such misuse.
    {breakline}{breakline}
    The Account Holder is responsible for ensuring that the Account is used in accordance with these terms and conditions of use, as well as with other conditions that are communicated in the App for the duration of the agreement. The Account Holder is exclusively responsible for the information that he/she submits in, or through, the App and consequently assumes all responsibility for its content.
    {breakline}{breakline}
    By registering an Account in the App, the Account Holder confirms and accepts the technical specifications, conditions and limitations of the App that are outlined in KRY International’s service and product descriptions, which are available in the App.
    {breakline}{breakline}
    By opening an Account in the App, the Account Holder accepts and confirms that the contact details provided may be used by KRY International to send offers and information related to the service via e-mail and push notifications. The Account Holder has the right to cancel this form of communication at any time following registration of the Account. KRY International is responsible for granting such a request without delay.
    {breakline}{breakline}
    Content that is published or provided by KRY International in the App is to be regarded as a complement to the medical guidance provided by the healthcare practitioner and is not intended as a substitute for the doctor’s assessment, diagnosis or treatment of any illness or disease. Use of the App is merely a substitute to traditional healthcare provision and is intended as a tool for medical practitioners to provide efficient healthcare to patients. KRY International does not assume responsibility as a Healthcare Provider in any regard.
    {breakline}{breakline}
    Given the technologically-based format of the service, the App and the service is limited to the specific forms of illnesses, diseases and health concerns that are outlined in the App.`,

  'termsOfUse.pricesAndPayment.title': 'Prices and payment',
  'termsOfUse.pricesAndPayment.text': `Prices and fees for a video meeting with a Healthcare Provider in the App, and other potential costs, are outlined in the list of current prices, which is available in the App prior to making a booking of a meeting with the Healthcare Provider.
    {breakline}{breakline}
    Payment for a video meeting in the App is processed before the meeting starts. Account holders cannot start a video meeting with the Healthcare Provider before the payment has been approved.
    {breakline}{breakline}
    This website is owned by ${APP_NAME}. The use of this website is subject to the Terms of Use as detailed below (in conjunction with our standard Terms and Conditions for the supply of Services, where applicable) which constitutes the full agreement between you and ${APP_NAME}. By using this website you acknowledge that you have read, accepted and agree to be bound these Terms and Conditions, and you agree that they are reasonable.
    {breakline}{breakline}
    If you intend to transact through this website (i.e. for the supply of any goods and/or services by any third party vendor) then you warrant that you are at least 18 years of age, that you have the power to enter into any agreement with such third party vendor and you acknowledge that such agreement creates binding and valid legal obligations upon you.
    {breakline}{breakline}
    If you do not agree with, or do not wish to accept the Terms and Conditions relating to this website, then please do not use this website. You are also advised that failure to accept our Terms and Conditions may result in your inability to access certain sections of this website.`,

  'termsOfUse.useThisWebsite.title': 'Use of this Website',
  'termsOfUse.useThisWebsite.text_1': 'In accessing or using this Website you agree that you will not:',
  'termsOfUse.useThisWebsite.text_2': `${APP_NAME} reserves the right to exercise whatever means it deems necessary to prevent unauthorised access to or use of this website, including, but not limited to, instituting technological barriers, or reporting your conduct to any person or entity.`,
  'termsOfUse.useThisWebsite.list.item_1':
    'Use any automated device, software, process or means to access, retrieve, scrape, or index this website, or any content on this website;',
  'termsOfUse.useThisWebsite.list.item_2':
    'Use any device, software, process or means to interfere or attempt to interfere with the proper working on this website;',
  'termsOfUse.useThisWebsite.list.item_3': `Undertake any action that will impose a burden or make excessive traffic demands on ${APP_NAME} infrastructure that it deems, in its sole discretion, to be unreasonable or disproportionate site usage;`,
  'termsOfUse.useThisWebsite.list.item_4':
    'Transmit spam, chain letters, contests, junk email, surveys, or other mass messaging, whether commercial in nature or not;',
  'termsOfUse.useThisWebsite.list.item_5': `Use this website, or any content from this website, in any manner which is, in ${APP_NAME} sole discretion, not reasonable and/or not for the purpose which it is made available;`,
  'termsOfUse.useThisWebsite.list.item_6':
    'Violate the rights of any person, including copyright, trade secret, privacy right, or any other intellectual property or proprietary right;',
  'termsOfUse.useThisWebsite.list.item_7':
    'Pose as any person or entity or attempt to solicit money, passwords or personal information from any person;',
  'termsOfUse.useThisWebsite.list.item_8': `Act in violation of any term or condition posed by ${APP_NAME}, or any applicable law;`,
  'termsOfUse.useThisWebsite.list.item_9':
    'Reproduce, republish, retransmit, modify, adapt, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit this website, or any content on this website, except as expressly authorised by Felix;',
  'termsOfUse.useThisWebsite.list.item_10':
    'Transmit or attempt to transmit any computer viruses, worms, defects, Trojan horses or other items of a destructive nature;',
  'termsOfUse.useThisWebsite.list.item_11': `Compromise the security or integrity of any network or system including any part of ${APP_NAME} network.`,

  'termsOfUse.immaterialRights.title': 'Immaterial rights, etc.',
  'termsOfUse.immaterialRights.text':
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut dolorum quidem qui modi sint esse, deserunt fugit? Doloribus, corporis fuga vitae sit quam vel minima molestiae, impedit possimus ut nam! Repudiandae dicta recusandae quis, sit ad obcaecati amet adipisci quam asperiores ex aperiam vero dolorem dolorum quasi pariatur animi quaerat magni, et molestiae fugiat cumque aut hic est porro! Iusto! Ex explicabo officiis veritatis laudantium aliquid id quasi, in, nostrum dolorem provident omnis commodi fugiat sint error voluptate libero obcaecati. Sunt odio error repudiandae, deserunt nam amet architecto mollitia beatae? Obcaecati aperiam temporibus amet consequuntur voluptate velit ipsum cupiditate, modi corrupti nam doloribus cumque ullam magni esse laborum architecto iure incidunt sequi exercitationem officia, facilis reiciendis. Ipsam iste recusandae provident? Corporis, earum animi possimus, nam pariatur quae illum error ea ex dolorum natus cupiditate suscipit? Voluptatum magnam quo laudantium nulla deleniti laborum dignissimos! Nam aut possimus dolorem expedita et dignissimos.',

  'termsOfUse.cancellationOfVideoMeeting.title': 'Cancellation of video meeting',
  'termsOfUse.cancellationOfVideoMeeting.text':
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut dolorum quidem qui modi sint esse, deserunt fugit? Doloribus, corporis fuga vitae sit quam vel minima molestiae, impedit possimus ut nam! Repudiandae dicta recusandae quis, sit ad obcaecati amet adipisci quam asperiores ex aperiam vero dolorem dolorum quasi pariatur animi quaerat magni, et molestiae fugiat cumque aut hic est porro! Iusto! Ex explicabo officiis veritatis laudantium aliquid id quasi, in, nostrum dolorem provident omnis commodi fugiat sint error voluptate libero obcaecati. Sunt odio error repudiandae, deserunt nam amet architecto mollitia beatae? Obcaecati aperiam temporibus amet consequuntur voluptate velit ipsum cupiditate, modi corrupti nam doloribus cumque ullam magni esse laborum architecto iure incidunt sequi exercitationem officia, facilis reiciendis. Ipsam iste recusandae provident? Corporis, earum animi possimus, nam pariatur quae illum error ea ex dolorum natus cupiditate suscipit? Voluptatum magnam quo laudantium nulla deleniti laborum dignissimos! Nam aut possimus dolorem expedita et dignissimos.',

  'termsOfUse.unauthorizedUseAndInformation.title': 'Unauthorized use and information',
  'termsOfUse.unauthorizedUseAndInformation.text':
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut dolorum quidem qui modi sint esse, deserunt fugit? Doloribus, corporis fuga vitae sit quam vel minima molestiae, impedit possimus ut nam! Repudiandae dicta recusandae quis, sit ad obcaecati amet adipisci quam asperiores ex aperiam vero dolorem dolorum quasi pariatur animi quaerat magni, et molestiae fugiat cumque aut hic est porro! Iusto! Ex explicabo officiis veritatis laudantium aliquid id quasi, in, nostrum dolorem provident omnis commodi fugiat sint error voluptate libero obcaecati. Sunt odio error repudiandae, deserunt nam amet architecto mollitia beatae? Obcaecati aperiam temporibus amet consequuntur voluptate velit ipsum cupiditate, modi corrupti nam doloribus cumque ullam magni esse laborum architecto iure incidunt sequi exercitationem officia, facilis reiciendis. Ipsam iste recusandae provident? Corporis, earum animi possimus, nam pariatur quae illum error ea ex dolorum natus cupiditate suscipit? Voluptatum magnam quo laudantium nulla deleniti laborum dignissimos! Nam aut possimus dolorem expedita et dignissimos.',

  'termsOfUse.termOfAgreement.title': 'Term of agreement',
  'termsOfUse.termOfAgreement.text':
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut dolorum quidem qui modi sint esse, deserunt fugit? Doloribus, corporis fuga vitae sit quam vel minima molestiae, impedit possimus ut nam! Repudiandae dicta recusandae quis, sit ad obcaecati amet adipisci quam asperiores ex aperiam vero dolorem dolorum quasi pariatur animi quaerat magni, et molestiae fugiat cumque aut hic est porro! Iusto! Ex explicabo officiis veritatis laudantium aliquid id quasi, in, nostrum dolorem provident omnis commodi fugiat sint error voluptate libero obcaecati. Sunt odio error repudiandae, deserunt nam amet architecto mollitia beatae? Obcaecati aperiam temporibus amet consequuntur voluptate velit ipsum cupiditate, modi corrupti nam doloribus cumque ullam magni esse laborum architecto iure incidunt sequi exercitationem officia, facilis reiciendis. Ipsam iste recusandae provident? Corporis, earum animi possimus, nam pariatur quae illum error ea ex dolorum natus cupiditate suscipit? Voluptatum magnam quo laudantium nulla deleniti laborum dignissimos! Nam aut possimus dolorem expedita et dignissimos.',

  'termsOfUse.productDevelopmentAndChanges.title': 'Product development and changes',
  'termsOfUse.productDevelopmentAndChanges.text':
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut dolorum quidem qui modi sint esse, deserunt fugit? Doloribus, corporis fuga vitae sit quam vel minima molestiae, impedit possimus ut nam! Repudiandae dicta recusandae quis, sit ad obcaecati amet adipisci quam asperiores ex aperiam vero dolorem dolorum quasi pariatur animi quaerat magni, et molestiae fugiat cumque aut hic est porro! Iusto! Ex explicabo officiis veritatis laudantium aliquid id quasi, in, nostrum dolorem provident omnis commodi fugiat sint error voluptate libero obcaecati. Sunt odio error repudiandae, deserunt nam amet architecto mollitia beatae? Obcaecati aperiam temporibus amet consequuntur voluptate velit ipsum cupiditate, modi corrupti nam doloribus cumque ullam magni esse laborum architecto iure incidunt sequi exercitationem officia, facilis reiciendis. Ipsam iste recusandae provident? Corporis, earum animi possimus, nam pariatur quae illum error ea ex dolorum natus cupiditate suscipit? Voluptatum magnam quo laudantium nulla deleniti laborum dignissimos! Nam aut possimus dolorem expedita et dignissimos.',

  'termsOfUse.forceMajeure.title': 'Force Majeure',
  'termsOfUse.forceMajeure.text':
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut dolorum quidem qui modi sint esse, deserunt fugit? Doloribus, corporis fuga vitae sit quam vel minima molestiae, impedit possimus ut nam! Repudiandae dicta recusandae quis, sit ad obcaecati amet adipisci quam asperiores ex aperiam vero dolorem dolorum quasi pariatur animi quaerat magni, et molestiae fugiat cumque aut hic est porro! Iusto! Ex explicabo officiis veritatis laudantium aliquid id quasi, in, nostrum dolorem provident omnis commodi fugiat sint error voluptate libero obcaecati. Sunt odio error repudiandae, deserunt nam amet architecto mollitia beatae? Obcaecati aperiam temporibus amet consequuntur voluptate velit ipsum cupiditate, modi corrupti nam doloribus cumque ullam magni esse laborum architecto iure incidunt sequi exercitationem officia, facilis reiciendis. Ipsam iste recusandae provident? Corporis, earum animi possimus, nam pariatur quae illum error ea ex dolorum natus cupiditate suscipit? Voluptatum magnam quo laudantium nulla deleniti laborum dignissimos! Nam aut possimus dolorem expedita et dignissimos.',

  'termsOfUse.notifications.title': 'Notifications',
  'termsOfUse.notifications.text':
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut dolorum quidem qui modi sint esse, deserunt fugit? Doloribus, corporis fuga vitae sit quam vel minima molestiae, impedit possimus ut nam! Repudiandae dicta recusandae quis, sit ad obcaecati amet adipisci quam asperiores ex aperiam vero dolorem dolorum quasi pariatur animi quaerat magni, et molestiae fugiat cumque aut hic est porro! Iusto! Ex explicabo officiis veritatis laudantium aliquid id quasi, in, nostrum dolorem provident omnis commodi fugiat sint error voluptate libero obcaecati. Sunt odio error repudiandae, deserunt nam amet architecto mollitia beatae? Obcaecati aperiam temporibus amet consequuntur voluptate velit ipsum cupiditate, modi corrupti nam doloribus cumque ullam magni esse laborum architecto iure incidunt sequi exercitationem officia, facilis reiciendis. Ipsam iste recusandae provident? Corporis, earum animi possimus, nam pariatur quae illum error ea ex dolorum natus cupiditate suscipit? Voluptatum magnam quo laudantium nulla deleniti laborum dignissimos! Nam aut possimus dolorem expedita et dignissimos.',

  'termsOfUse.personalData.title': 'Personal data',
  'termsOfUse.personalData.text':
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut dolorum quidem qui modi sint esse, deserunt fugit? Doloribus, corporis fuga vitae sit quam vel minima molestiae, impedit possimus ut nam! Repudiandae dicta recusandae quis, sit ad obcaecati amet adipisci quam asperiores ex aperiam vero dolorem dolorum quasi pariatur animi quaerat magni, et molestiae fugiat cumque aut hic est porro! Iusto! Ex explicabo officiis veritatis laudantium aliquid id quasi, in, nostrum dolorem provident omnis commodi fugiat sint error voluptate libero obcaecati. Sunt odio error repudiandae, deserunt nam amet architecto mollitia beatae? Obcaecati aperiam temporibus amet consequuntur voluptate velit ipsum cupiditate, modi corrupti nam doloribus cumque ullam magni esse laborum architecto iure incidunt sequi exercitationem officia, facilis reiciendis. Ipsam iste recusandae provident? Corporis, earum animi possimus, nam pariatur quae illum error ea ex dolorum natus cupiditate suscipit? Voluptatum magnam quo laudantium nulla deleniti laborum dignissimos! Nam aut possimus dolorem expedita et dignissimos.',

  'termsOfUse.generalConditions.title': 'General conditions',
  'termsOfUse.generalConditions.text':
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut dolorum quidem qui modi sint esse, deserunt fugit? Doloribus, corporis fuga vitae sit quam vel minima molestiae, impedit possimus ut nam! Repudiandae dicta recusandae quis, sit ad obcaecati amet adipisci quam asperiores ex aperiam vero dolorem dolorum quasi pariatur animi quaerat magni, et molestiae fugiat cumque aut hic est porro! Iusto! Ex explicabo officiis veritatis laudantium aliquid id quasi, in, nostrum dolorem provident omnis commodi fugiat sint error voluptate libero obcaecati. Sunt odio error repudiandae, deserunt nam amet architecto mollitia beatae? Obcaecati aperiam temporibus amet consequuntur voluptate velit ipsum cupiditate, modi corrupti nam doloribus cumque ullam magni esse laborum architecto iure incidunt sequi exercitationem officia, facilis reiciendis. Ipsam iste recusandae provident? Corporis, earum animi possimus, nam pariatur quae illum error ea ex dolorum natus cupiditate suscipit? Voluptatum magnam quo laudantium nulla deleniti laborum dignissimos! Nam aut possimus dolorem expedita et dignissimos.',

  'termsOfUse.legalProceedings.title': 'Legal Proceedings',
  'termsOfUse.legalProceedings.text':
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut dolorum quidem qui modi sint esse, deserunt fugit? Doloribus, corporis fuga vitae sit quam vel minima molestiae, impedit possimus ut nam! Repudiandae dicta recusandae quis, sit ad obcaecati amet adipisci quam asperiores ex aperiam vero dolorem dolorum quasi pariatur animi quaerat magni, et molestiae fugiat cumque aut hic est porro! Iusto! Ex explicabo officiis veritatis laudantium aliquid id quasi, in, nostrum dolorem provident omnis commodi fugiat sint error voluptate libero obcaecati. Sunt odio error repudiandae, deserunt nam amet architecto mollitia beatae? Obcaecati aperiam temporibus amet consequuntur voluptate velit ipsum cupiditate, modi corrupti nam doloribus cumque ullam magni esse laborum architecto iure incidunt sequi exercitationem officia, facilis reiciendis. Ipsam iste recusandae provident? Corporis, earum animi possimus, nam pariatur quae illum error ea ex dolorum natus cupiditate suscipit? Voluptatum magnam quo laudantium nulla deleniti laborum dignissimos! Nam aut possimus dolorem expedita et dignissimos.',
};

export default locales;

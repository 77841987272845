const locales = {
  'account.feelFreeToUpdatePassword': 'Feel free to update your password so your account stays secure',
  'account.enterCurrentPassword': 'Verify password (enter your current password)',
  'account.canDeactivateAccount': 'If you want to take a break, you can deactivate your account for some time',
  'account.canActivateAccount':
    'If you want to return the full functionality of your account, you can activate your account back',
  'account.enterNewPassword': 'Enter the new unique password to protect your account',
  'account.passwordUpdatedSuccessfully': 'Your password has been updated successfully',
  'account.emailChangedSuccessfully': 'Your email has been changed',
  'account.confirmationLinkToEmailSent': 'We sent a confirmation link to your email address',
  'account.followTheLinkToConfirmEmail': 'Follow the link in the email to confirm your email address',
  'account.personalInformationUpdatedSuccessfully': 'Your personal information has been updated successfully',
  'account.medicalTitleLicenses': 'Your medical title and license has been updated successfully',
  'account.contactInformationUpdatedSuccessfully': 'Your contact information has been updated successfully',
  'account.accountHasBeenDeactivated': 'Your account has been deactivated',
  'account.accountHasBeenActivated': 'Your account has been activated',
  'account.areYouSureYouWouldLikeDeactivateAccount': 'Are you sure that you would like to deactivate your account?',
  'account.areYouSureYouWantActivateAccount': 'Are you sure you want to activate your account back?',
  'account.updateEmail.success': 'The email was sent successfully. Please check your email.',

  'account.calendar.addWorkingBrake': 'Add your working brake',
  'account.calendar.workingBrakeFrom': 'Working brake From',
  'account.calendar.workingBrakeTo': 'Working brake To',
  'account.calendar.workBreakDescription': 'Indicate the time period when you will be absent to work',
  'account.calendar.additionalWorkingHoursCount': '+{count} more times',
  'account.calendar.dateSpecificBrake': 'Date specific brake',
  'account.calendar.selectDateYouWantAssignSpecificHours': 'Select the date you want to assign specific hours',

  'account.licenses.stateMedicalBoardLicenses': 'State medical board licenses',
  'account.licenses.boardLicenseNumber': 'Board License Number',
  'account.licenses.boardLicenseType': 'Board License Type',

  'account.cancellationScenario': 'Cancellation scenario',
  'account.72Hours': '72 hours or earlier',
  'account.48Hours': '48 hours',
  'account.24Hours': '24 hours before',
  'account.1Hour': '1 hour before',
  'account.ofIndicatedPrice': '% of the indicated price',

  'account.payoutInformation': 'Payout information',
  'account.payoutMethod': 'Payout method',
  'account.addPayoutMethod': 'Add payout method',
  'account.payoutMethodSubheader': 'You currently have no saved payout method',
  'account.stripeStatus.complete': 'Completed',
  'account.stripeStatus.restricted':
    'Restricted means the account has payouts or payments disabled. Additional information usually needs to be collected to enable these accounts.',
  'account.stripeStatus.pending': 'Waiting for verification',
};

export default locales;

import assessment from '../assessments/en';

const locales = {
  'appointment.upcoming.empty': 'There are no upcoming appointments yet.',
  'appointment.inProgress.empty': 'There are no in progress appointments yet.',
  'appointment.cancelled.empty': 'There are no cancelled appointments yet.',
  'appointment.past.empty': 'There are no past appointments yet.',

  'appointment.menu.reschedule': 'Reschedule an appointment',
  'appointment.menu.cancel': 'Cancel an appointment',
  'appointment.menu.viewConsultationPage': 'View consultation page',

  'appointment.join.button': 'Join consultation',
  'appointment.joinVideoConference.button': 'Join video conference',
  'appointment.consultation.button': 'View consultation page',
  'appointment.pay.button': 'Pay',

  'appointment.status.label.inProgress': 'In progress',
  'appointment.status.label.scheduled': 'Scheduled',
  'appointment.status.label.rescheduled': 'Rescheduled',
  'appointment.status.label.cancelled': 'Cancelled',
  'appointment.status.label.past': 'Past',
  'appointment.status.label.assessment': 'Waiting for assessment',
  'appointment.status.label.pay': 'Waiting for payment',

  'appointment.cancelled.reason': 'Cancelation reason (Canceled by {cancelledBy})',
  'appointment.cancelled.refunded': 'Refunded',

  'appointment.questionnaire.view': 'View questionnaire',

  'appointment.addToGoogleCalendar': 'Add to Google Calendar',
  'appointment.addToOutlookAppleCalendar': 'Add to Outlook / Apple Calendar',
  'appointment.started': 'Started',
  'appointment.status': 'Status',
  'appointment.specialty': 'Specialty',
  'appointment.symptom': 'Symptom',
  'appointment.doctor': 'Doctor',
  'appointment.patient': 'Patient',
  'appointment.price': 'Price',
  'appointment.stateLicense': 'State license',
  'appointment.dateAndTime': 'Date & Time',
  'appointment.timezone': 'Timezone',
  'appointment.startTime': 'Start time',
  'appointment.commentForDoctor': 'Comment for the doctor',
  'appointment.consultation.startIn': 'Consultation starts in',
  'appointment.back.toAppointments': 'Back to appointments',
  'appointment.consultationInfo': 'Consultation info',
  'appointment.menu.edit.button': 'Edit',
  'appointment.menu.copy.button': 'Copy',
  'appointment.menu.remove.button': 'Remove',
  'appointment.save.button': 'Save',
  'appointment.send.button': 'Send',
  'appointment.consultationTime.expired':
    'Chat is unavailable. Your appointment has not started or has already completed.',
  'appointment.edit.message.title': 'Editing message',
  'appointment.message.placeholder': 'Type your message here',
  'bookAppointment.questionnaire.title': 'Questionnaire',
  'bookAppointment.questionnaire.whatBrings.label': 'What brings you here today?',
  'bookAppointment.questionnaire.discussAbout.label': 'What would you like to discuss with your health care provider?',
  'bookAppointment.questionnaire.symptomsDescriptive.label': 'If symptoms exist please be descriptive',
  'bookAppointment.questionnaire.temperature.label': 'Temperature',
  'bookAppointment.questionnaire.bloodPressure.label': 'Blood pressure',
  'bookAppointment.questionnaire.heartRate.label': 'Heart rate',
  'bookAppointment.questionnaire.respiratoryRate.label': 'Respiratory rate',
  'bookAppointment.questionnaire.age.label': 'Age',
  'bookAppointment.questionnaire.birthDate.label': 'Birth date',
  'bookAppointment.questionnaire.maritalStatus.label': 'Marital status',
  'bookAppointment.questionnaire.genderIdentity.label': 'Gender identity',
  'bookAppointment.questionnaire.sex.label': 'Sex',
  'bookAppointment.questionnaire.ethnicity.label': 'Ethnicity',
  'bookAppointment.questionnaire.medications.label': 'Medications',
  'bookAppointment.questionnaire.whatMedications.label': 'What medications, if any, do you currently take?',
  'bookAppointment.questionnaire.temperature.preview.label': '{temperature} ℉',
  'bookAppointment.questionnaire.bloodPressure.preview.label': '{bloodPressure} mmHg',
  'bookAppointment.questionnaire.heartRate.preview.label': '{heartRate} beats per minute',
  'bookAppointment.questionnaire.respiratoryRate.preview.label': '{respiratoryRate} breaths per minute',

  'appointment.canceled.success.message': 'Your appointment has been successfully canceled',
  'appointment.canceled.modal.title': 'Cancel an appointment',
  'appointment.canceled.title': 'Please, indicate the reason why you want to cancel the appointment:',
  'appointment.canceled.reason.first': 'I have problems with my device',
  'appointment.canceled.reason.second': 'I have internet issues',
  'appointment.canceled.reason.third': 'I have already receive medical help form other mean',
  'appointment.canceled.reason.four': 'My medical problem disappear',
  'appointment.canceled.confirm.warning.message': 'You can cancel your appointment just twice, you can read',
  'appointment.canceled.confirm.warning.cancelPolicy.link': "Doctor's cancelation policy",
  'appointment.canceled.confirm.message': 'Are you sure you want to cancel your appointment?',

  'appointment.popover.cancelationReason': 'Cancelation reason (patient)',
  'appointment.popover.patient': 'Patient',

  'appointment.assessmentSavedAsDraft': 'Assessment saved as a Draft',
  'appointment.viewAssessments': 'View assessment',
  'appointment.addAssessments': 'Add assessment',
  'appointment.allFiles': 'All files ({fileLength})',
  'appointment.newAssessment': 'New assessment',
  'appointment.files.showMore': 'Show more ({count})',
  'appointment.assessment.clearAll': 'Clear all',
  'appointment.assessment.saveAsDraft': 'Save as Draft',
  'appointment.assessment.subjective': 'Subjective',
  'appointment.assessment.objective': 'Objective',
  'appointment.assessment.assessment': 'Assessment',
  'appointment.assessment.plan': 'Plan',
  'appointment.assessment.diagnose': 'Diagnose (Optional)',
  'appointment.assessment.choseCategories': 'Choose categories',

  'appointment.assessment.prescription': 'Prescription Medication',
  'appointment.assessment.prescription.drugName': 'Drug Name',
  'appointment.assessment.prescription.doseStrength': 'Dose Strength',
  'appointment.assessment.prescription.dosageForm': 'Dosage Form',
  'appointment.assessment.prescription.quantityPrescribed': 'Quantity prescribed',
  'appointment.assessment.prescription.directionsForUser': 'Directions for use',
  'appointment.assessment.prescription.numberOfRefills': 'Number of refills',

  'appointment.assessment.appointment_date_and_time': 'Appointment date and time',

  'appointment.assessment.imaging': 'Imaging',
  'appointment.assessment.imaging.imagingModality': 'Imaging modality',
  'appointment.assessment.imaging.directionsForPatientPreparations': 'Directions for patient preparations',

  'appointment.assessment.bloodWork': 'Blood Work',
  'appointment.assessment.bloodWork.bloodWorkBeingOrdered': 'Blood work being ordered',
  'appointment.assessment.bloodWork.directionsForPatientPreparations': 'Directions for patient preparations',

  'appointment.assessment.biopsy': 'Biopsy',
  'appointment.assessment.biopsy.describeLesionBeingBiopsied': 'Describe lesion being biopsied',
  'appointment.assessment.biopsy.directionsForPatientPreparations': 'Directions for patient preparations',

  'appointment.assessment.therapy': 'Therapy',
  'appointment.assessment.therapy.physical': 'Physical',
  'appointment.assessment.therapy.occupational': 'Occupational',
  'appointment.assessment.therapy.other': 'Other',
  'appointment.assessment.therapy.lengthInTimesPerWeekPerMonth': 'Length in times per week per month',
  'appointment.assessment.therapy.directionsForPatientPreparations': 'Directions for patient preparations',

  'appointment.assessment.medicalEquipment': 'Medical Equipment',
  'appointment.assessment.medicalEquipment.freeTextEquipmentNeeded': 'Free text equipment needed',
  'appointment.assessment.medicalEquipment.directionsForPatientUse': 'Directions for patient use',

  'appointment.assessment.sickNote': 'Sick Note',
  'appointment.assessment.sickNote.sickNote': 'Description',

  'appointment.provider.button.currentDay': 'Current Day',
  'appointment.provider.button.currentMonth': 'Current Month',

  'assessment.added.success': 'Assessment has been added',
  'assessment.updated.success': 'Assessment has been updated',

  'appointment.newMessage.markAsRead': 'Mark as read',
  'appointment.newMessage.description':
    '{count} {count, plural, one {new message} other {new messages}}  since {time} on {date}',
  'appointment.chat.removed.success': 'Message has been removed',
  'appointment.chat.attachment.added.success': '{filename} file has been added',
  'appointment.chat.joined.message': '{fullName} has joined the video conference',

  ...assessment,
};

export default locales;

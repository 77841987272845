import { APP_NAME, MAX_EMAIL_LENGTH, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'constants/app';

const locales = {
  'yup.mixed.required': 'Is required',
  'yup.string.email': 'Email is invalid',
  'yup.string.min': 'Must be at least {min} characters long',
  'yup.string.max': 'Must be {max} or fewer characters long',
  'yup.number.min': 'Must be at least {min}',
  'yup.number.max': 'Must be {max} or less',
  'yup.number.positive': 'Must be a positive number',
  'yup.number.integer': 'Must be an integer',
  'yup.number.moreThan': 'Price must be equal or greater than zero',
  'yup.string.isHexColor': 'Invalid HEX color code',
  'yup.mixed.datesEquality': 'The dates are equal',
  'yup.date.future': 'must be in the future',
  'yup.mixed.startIsGreaterThanEnd': 'The start is greater than the end',
  'yup.mixed.bookingTime.required': 'Please choose a booking time',

  'shared.seo.meta.description': `${APP_NAME}: All-in-one online client meeting tool to streamline consulting services.`,

  'shared.forgotPassword': 'Forgot password?',
  'shared.requiredPassword': 'Password is required',
  'shared.passwordMin': `Password must be ${MIN_PASSWORD_LENGTH} or more characters long`,
  'shared.passwordMax': `Password must be ${MAX_PASSWORD_LENGTH} or fewer characters long`,
  'shared.resendLink': 'Resend link',
  'shared.requiredEmail': 'Email is required',
  'shared.confirmYourEmail': `Confirm your email`,
  'shared.emailVerified': 'Email verified',
  'shared.emailsShouldNotMatch': "Shouldn't match current email",
  'shared.emailMax': `Email must be ${MAX_EMAIL_LENGTH} or fewer characters long`,
  'shared.invalidEmail': `Email has a wrong format`,
  'shared.emailAddressAlreadyRegistered': 'The email address is already registered',
  'shared.passwordMustContain':
    'Password must contain at least 1 upper case latin letter, 1 lower case latin letter, 1 digit and be at least 8 characters long',
  'shared.useMinimumPasswordLength': 'Use a minimum password length of 8 or more characters',
  'shared.youCanUseUppercaseCharacters': 'You can use uppercase characters, numbers, and symbols',
  'shared.yourPasswordIsStrongEnough': 'Your password is strong enough!',
  'shared.passwordsShouldNotMatch': "Shouldn't match current password",
  'auth.emailUpdateConfirmation': 'Update email confirmation',
  'shared.confirm': 'Confirm',
  'shared.back': 'Back',

  'shared.timezone.hawaii': 'Hawaii Time (HST)',
  'shared.timezone.alaska': 'Alaska Time (AKDT)',
  'shared.timezone.pacific': 'Pacific Time (PDT)',
  'shared.timezone.mountain': 'Mountain Time (MDT)',
  'shared.timezone.central': 'Central Time (CDT)',
  'shared.timezone.eastern': 'Eastern Time (EDT)',

  'shared.search': 'Search',
  'shared.activate': 'Activate',
  'shared.submit': 'Submit',
  'shared.save': 'Save',
  'shared.change': 'Change',
  'shared.deactivate': 'Deactivate',
  'shared.discard': 'Discard',
  'shared.cancel': 'Cancel',
  'shared.remove': 'Remove',
  'shared.ok': 'Ok',
  'shared.yes': 'Yes',
  'shared.no': 'No',
  'shared.skip': 'Skip',
  'shared.clear': 'Clear',
  'shared.undo': 'Undo',
  'shared.done': 'Done',
  'sidebar.example': 'Example',
  'shared.termsOfUse': 'Terms of Use',
  'shared.privacyPolicy': 'Privacy Policy',
  'shared.hipaaAndPhi': 'HIPAA and PHI',
  'shared.copyright': `2023 © Copyright ${APP_NAME}. All rights reserved`,
  'shared.logIn': 'Log in',
  'shared.signUp': 'Sign up',
  'shared.logOut': 'Log out',
  'shared.registerNow': 'Register now',
  'shared.createAnAccount': 'Create an account',
  'shared.info': 'INFO',
  'shared.faq': 'FAQ',
  'shared.aboutUs': 'About us',
  'shared.workWithUs': 'Work with us',
  'shared.contactUs': 'Contact us',
  'shared.email': 'Email',
  'shared.password': 'Password',
  'shared.pleaseFillFieldsToContinue': 'Please fill in the fields to continue',
  'shared.firstName': 'First name',
  'shared.routingNumber': 'Routing number',
  'shared.routingNumberPlaceholder': '11000000',
  'shared.accountNumber': 'Account number',
  'shared.accountNumberPlaceholder': '000123456789',
  'shared.lastName': 'Last name',
  'shared.birthDate': 'Birth date',
  'shared.timeZone': 'Time zone',
  'shared.gender': 'Gender',
  'shared.genderIdentity': 'Gender identity',
  'shared.sex': 'Sex',
  'shared.maritalStatus': 'Marital status',
  'shared.status': 'Status',
  'shared.age': 'Age',
  'shared.aboutMe': 'About me',
  'shared.male': 'Male',
  'shared.female': 'Female',
  'shared.nonOther': 'Non (Other)',
  'shared.otherNone': 'Non (Other)',
  'shared.marry': 'Marry',
  'shared.divorce': 'Divorce',
  'shared.other': 'Other',
  'shared.next': 'Next',
  'shared.edit': 'Edit',
  'shared.yourAppointment': 'Your appointment {date}',
  'shared.date.unavailable': 'Unavailable',
  'shared.sessionBookWill': 'The session you book will last 15 minutes',
  'shared.uploadImage': 'Upload image',
  'shared.uploadProfileImage': 'Upload profile image',
  'shared.profileImage': 'Profile image',
  'shared.uploadAttachmentTooMany': 'Too many files selected: Attach up to {count} files',
  'shared.uploadImageOrDropItHere': 'Upload image or drop it here',
  'shared.uploadImageError': 'Upload image error',
  'shared.uploadImageRules': 'You can upload only JPG, JPEG and PNG. Size of image shouldn`t be more than 10 MB',
  'shared.uploadImageRulesModal': 'Image format: JPG, JPEG or PNG. Max size: 10 Mb.',
  'shared.uploadAttachmentRules': 'You can upload up to 10 files. Formats PDF, DOC, DOCX. File size up to 20MB',
  'shared.changeImage': 'Change image',
  'shared.errorDuringUploadingFile': 'Error during uploading file. Incorrect format or size',
  'shared.areYouSureYouWantRemoveProfilePicture': 'Are you sure you want to remove your profile picture?',
  'shared.areYouSureYouWantRemoveAttachment': 'Are you sure you want to remove this file?',
  'shared.areYouSureYouWantRemoveMessage': 'Are you sure you want to remove this message for you and {fullName}?',
  'shared.deleteMessage': 'Delete message',
  'shared.delete': 'Delete',
  'shared.state': 'State',
  'shared.zip': 'Zip code',
  'shared.city': 'City',
  'shared.streetAddress': 'Street address',
  'shared.address': 'Address',
  'shared.phoneNumber': 'Phone number',
  'shared.text': 'Text',
  'shared.addSignature': 'Add signature',
  'shared.pleaseTypeSignInForm': 'Please type a sign in a form',
  'shared.backToPreviousStep': 'Back to previous step',
  'shared.congratulations': 'Congratulations',
  'shared.welcomeToApp': `Welcome to ${APP_NAME}`,
  'shared.undergraduateSchool': 'Undergraduate School and Major',
  'shared.graduateSchool': 'Graduate School',
  'shared.collegeUniversitySchool': 'Medical College / Medical University / Medical School',
  'shared.familyMedicine': 'Family Medicine',
  'shared.specialty': 'Specialty',
  'shared.sunday': 'Sunday',
  'shared.monday': 'Monday',
  'shared.tuesday': 'Tuesday',
  'shared.wednesday': 'Wednesday',
  'shared.thursday': 'Thursday',
  'shared.friday': 'Friday',
  'shared.saturday': 'Saturday',
  'shared.investigate': 'Investigate',
  'shared.yourTimeSlotIsOverlapped': 'Your time slot is overlapped',
  'shared.expirationDate': 'Expiration Date',
  'shared.licenseExpirationDate': 'License Expiration Date',
  'shared.licenseNumber': 'License Number',
  'shared.stateMedicalBoardLicense': 'State Medical Board',
  'shared.addState': 'Add state',
  'shared.selectDate': 'Select date',
  'shared.medicalDegree': 'Medical degree',
  'shared.npi': 'National Provider Identifier (NPI)',
  'shared.boardLicenseType': 'Board Certification Type',
  'shared.boardLicenseNumber': 'Board Certification Number',
  'shared.addLicenseType': 'Add license type',
  'shared.title': 'Title',
  'shared.resetYourPassword': 'Reset your password',
  'shared.sendRecoveryLink': 'Send recovery link',
  'shared.backToLogin': 'Back to Log in',
  'shared.goToLogin': 'Go to Log in',
  'shared.checkYourEmail': 'Check your email',
  'shared.createNewPassword': 'Create new password',
  'shared.assessment': 'Assessment',
  'shared.appointments': 'Appointments',
  'shared.videoConference': 'Video Conference',
  'shared.consultation': 'Consultation',
  'shared.thereAreNoAppointmentsYet': 'There are no appointments yet.',
  'shared.location': 'Location',
  'shared.patients': 'Patients',
  'shared.doctors': 'Doctors',
  'shared.successSendContactUs': 'Your question was successfully sent. Our admin will reply to your email shortly.',
  'shared.account': 'Account',
  'shared.myProfile': 'My profile',
  'shared.accountSettings': 'Account settings',
  'shared.paymentInformation': 'Payment information',
  'shared.notificationSettings': 'Notification settings',
  'shared.notifications': 'Notifications',
  'shared.thereAreNoNotificationsYet': 'There are no notifications yet',
  'shared.markAsRead': 'Mark as read',
  'shared.support': 'Support',
  'shared.race': 'Race',
  'shared.ethnicity': 'Ethnicity',
  'shared.internship': 'Internship',
  'shared.residency': 'Residency',
  'shared.fellowship': 'Fellowship',
  'shared.publishedResearch': 'Published Research',
  'shared.volunteerWork': 'Volunteer Work',
  'shared.furtherProfessionalInterests': 'Further Professional Interests',
  'shared.goals': 'Goals',
  'shared.focus': 'Focus',
  'shared.hobbies': 'Hobbies',
  'shared.uploadAttachment': 'Upload Attachment',
  'shared.deleteAttachment': 'Delete Attachment',
  'shared.delete.message': 'Are you sure you want to delete this message for everyone?',
  'shared.upload': 'Upload',
  'shared.edited': 'Edited',
  'shared.languages': 'Languages',
  'shared.personalInformation': 'Personal Information',
  'shared.contactInformation': 'Contact Information',
  'shared.medicalTitleAndLicense': 'Medical title and license',
  'shared.specialityWorkingHours': 'Speciality and working hours',
  'shared.backToView': 'Back to view',
  'shared.error': 'Error',
  'shared.percentOfIndicatedPrice': '{percent}% of the indicated price',
  'shared.currentEmail': 'Your current email',
  'shared.updateEmail': 'Update email',
  'shared.changePassword': 'Change password',
  'shared.updatePassword': 'Update password',
  'shared.deactivateAccount': 'Deactivate account',
  'shared.activateAccount': 'Activate account',
  'shared.deactivateOrActivateAccount': 'Deactivate or activate account',
  'shared.cancelationFee': 'Cancelation fee',
  'shared.currentPassword': 'Current Password',
  'shared.newPassword': 'New password',
  'shared.patientHealthProfile': 'Patient health profile',
  'shared.changesHasBeenSaved': 'Changes has been saved',
  'shared.goToAccountSettings': 'Go to Account settings',
  'shared.speciality': 'Speciality',
  'shared.consultationPrice': 'Consultation price',
  'shared.listView': 'List view',
  'shared.files': 'Files',
  'shared.calendarView': 'Calendar view',
  'shared.addYourConsultationPrices': 'Add your consultation prices (per 1 hour)',
  'shared.completeYourWorkingHours': 'Complete your working hours',
  'shared.forPrimaryCareDoctors': 'For Primary Care Doctors the default appointment duration will be 15 min',
  'shared.setYourWeeklyHours': 'Set your weekly hours',
  'shared.date': 'Date',
  'shared.editDate': 'Edit date',
  'shared.editAllWeekdays': 'Edit all {weekday}s',
  'shared.resetToWeeklyHours': 'Reset to weekly hours',
  'shared.apply': 'Apply',
  'shared.reset': 'Reset',
  'shared.selectDateYouWantToAssignSpecificHour': 'Select the date you want to assign specific hours',
  'shared.dateSpecificAvailability': 'Date-specific availability',
  'shared.repeatsWeekly': 'Repeats weekly',
  'shared.incorrectFormat': 'Incorrect format',
  'shared.withTwoNumberValues': 'Should be written in format 120/80',
  'shared.notFromYourState': 'Not from your state',
  'shared.bookAppointment': 'Book an appointment',
  'shared.viewAllAvailability': 'View all availability',
  'shared.session': 'Session',
  'shared.sortBy': 'Sort by',
  'shared.priceRange': 'Price range',
  'shared.providersAmount': '{amount} providers',
  'shared.nothingMatchesYourSearch': 'Nothing matches your search',
  'shared.doctorsSpeciality': 'Doctors speciality',
  'shared.clearFilter': 'Clear filter',
  'shared.specialtySymptomDoctorsType': 'Specialty, symptom, doctors type...',
  'shared.symptom': 'Symptom',
  'shared.relevance': 'Relevance',
  'shared.priceHighToLow': 'Price high to low',
  'shared.priceLowToHigh': 'Price low to high',
  'shared.systemSettings': 'System settings',
  'shared.emailSettings': 'Email settings',
  'shared.today': 'Today',
  'shared.yesterday': 'Yesterday',
  'shared.otherDay': '{date}',
  'shared.moreItems': '+{count} {count, plural, one {more item} other {more items}}',

  'shared.alt.empty': 'Empty',
  'shared.alt.envelope': 'Envelope',
  'shared.alt.signature': 'Signature',

  'shared.bookAppointment.modal.title': 'All availability',
  'shared.bookAppointment.modal.submit.button': 'Book',

  'shared.medications': 'Medications',
  'shared.whatMedications': 'What medications, if any, do you currently take?',
  'shared.allergies': 'Allergies',
  'shared.indicateAllAllergies':
    'Indicate all the allergies you have to medications and/or food & describe the reaction below',
  'shared.surgeries': 'Surgeries',
  'shared.ifYouHaveHadSurgeries': 'If you have had surgeries, then text box to type reason and number of times',
  'shared.hospitalizations': 'Hospitalizations',
  'shared.ifYouHaveHadHospitalizations':
    'If you have had hospitalizations, then text box to type reason and number of times.',
  'shared.pleaseUploadImage': 'Please upload the image',
  'shared.passwordUpdated': 'Your password has been updated',
  'shared.payout.disconnectModalTittle': 'Delete payout method',
  'shared.payout.disconnectDescription': 'Are you sure that you would like to delete payout method?',
  'shared.hide': 'Hide',
  'shared.viewMore': 'View more',

  'shared.downloadPdf': 'Download PDF',
  'shared.diagnose': 'Diagnose (ICD Code)',

  'shared.reschedule': 'Reschedule',
  'shared.reschedule.preview.title': 'Reschedule an appointment',
  'shared.reschedule.preview.warning': 'You can reschedule your appointment just twice',
  'shared.reschedule.preview.description': 'Are you sure that you want to reschedule an appointment?',
  'shared.reschedule.success.message': 'Your appointment has been rescheduled',

  'shared.notificationMessage.userProfile.appointment.cancelAppointment': `You have canceled your appointment with {patientFullName} for {symptomName} on {userStartTime}. The cost has been refunded to the patient.`,
  'shared.notificationMessage.userProfile.appointment.cancelAppointmentWithoutAssessment': `Your appointment with {patientFullName} for {symptomName} on {userStartTime} was cancelled due to a lack of assessment. The cost has been refunded to the patient.`,
  'shared.notificationMessage.userProfile.appointment.cancelAppointmentByAdmin': `Your appointment with {patientFullName} for {symptomName} on {userStartTime} have been canceled by administrator.`,
  'shared.notificationMessage.userProfile.appointment.newAppointment': `You have a new appointment for {symptomName} with {patientFullName} on {userStartTime}.`,
  'shared.notificationMessage.userProfile.appointment.newMessage': `You have a new message from the {patientFullName} in the appointment for {symptomName} at {userStartTime}.`,
  'shared.notificationMessage.userProfile.appointment.rescheduleAppointment': `{patientFullName} has made a reschedule for the appointment for {symptomName} from {rescheduleUserStartTime} {rescheduleUserEndTime}.`,
  'shared.notificationMessage.userProfile.appointment.providerMissedAppointment': `You missed the appointment for {symptomName} {patientFullName} at {userStartTime}. The cost has been refunded to the patient.`,
  'shared.notificationMessage.userProfile.appointment.appointmentPassed': `Your appointment with {patientFullName} has passed successfully.`,
  'shared.notificationMessage.userProfile.appointment.joinedToVideoCall': `Patient {patientFullName} joined to video conference.`,
  'shared.notificationMessage.userProfile.appointment.assessmentReminder': `Fill out assessment after the appointment for {symptomName} {patientFullName} at {userStartTime}.`,
  'shared.notificationMessage.patientProfile.appointment.cancelAppointment': `Your appointment with {doctorFullName} for {symptomName} on {patientStartTime} has been cancelled.`,
  'shared.notificationMessage.patientProfile.appointment.cancelAppointmentWithoutAssessment': `{patientFullName} didn't provide an assessment after the appointment {symptomName} on {userStartTime}. The cost has been refunded to the patient.`,
  'shared.notificationMessage.patientProfile.appointment.cancelAppointmentByAdmin': `Your appointment with {patientFullName} for {symptomName} on {userStartTime} has been cancelled by administrator.`,
  'shared.notificationMessage.patientProfile.appointment.newAppointment': `You have a new appointment for {symptomName} with {doctorFullName} on {patientStartTime}`,
  'shared.notificationMessage.patientProfile.appointment.newMessage': `You have a new message from the {doctorFullName} in the appointment for {symptomName} at {patientStartTime}.`,
  'shared.notificationMessage.patientProfile.appointment.appointmentPassed': `Your appointment with {doctorFullName} has passed successfully.`,
  'shared.notificationMessage.patientProfile.appointment.rescheduleAppointment': `You has reschedule an appointment with {doctorFullName} for {symptomName} from {reschedulePatientStartTime} {reschedulePatientEndTime}.`,
  'shared.notificationMessage.patientProfile.appointment.providerMissedAppointment': `{doctorFullName} has missed appointment with you for {symptomName} on {patientStartTime}. The cost has been refunded to the you soon.`,
  'shared.notificationMessage.patientProfile.appointment.joinedToVideoCall': `Provider {doctorFullName} joined to video conference.`,
  'shared.notificationMessage.notImplemented': 'Not implemented',
  'shared.notificationMessage.dateTime': '{date} at {time}',
  'shared.payoutInformation': 'Payout information',
  'shared.accountStatus': 'Account status',

  'shared.bookAppointment.modal.error.title': 'Booking appointment error',
  'shared.bookAppointment.modal.error.message': 'Oops! Your booking appointment time has ended.',
  'shared.offline': 'You are offline now',
  'shared.copyToClipboard': 'Copied to clipboard',
  'shared.error.generalNotification': 'Sorry, something went wrong. Try reloading the page.',
  'shared.iWantToReceiveNotifications': 'I want to receive notifications',
  'shared.dontVerifiedUserData': 'Patient appointments are temporarily unavailable. Please check your {link} page.',
  'shared.websiteIsTestingMode':
    'The website is currently in testing mode, and all payments are processed without actually deducting funds from payment cards.',

  'errors.returnHome': 'Return Home',

  'errors.notFound.title': 'Oops, something went wrong!',
  'errors.notFound.subtitle': 'ERROR: 404',
  'errors.notFound.description': 'The page you requested does not exist.',

  'errors.forbidden.title': 'Forbidden!',
  'errors.forbidden.subtitle': 'ERROR: 403',
  'errors.forbidden.description': 'Access to this resource on the server is denied.',

  'errors.internalServerError.title': 'Oops, something went wrong!',
  'errors.internalServerError.subtitle': 'ERROR: 500',
  'errors.internalServerError.description':
    'We apologize and are fixing the problem. Please try again at a later stage.',
  'static.contactUs': 'Contact us',
};

export default locales;

const locales = {
  'notification.settings.notificationsSystemCanSendMe': 'Notifications system can send me',
  'notification.settings.inAppCommunicationNotifications': 'In-app communication notifications',
  'notification.settings.inAppNewChatMsg': 'Receive in-app notifications about new chat messages or files',
  'notification.settings.inAppAppointmentNotifications': 'In-app appointment notifications',
  'notification.settings.inAppAppointments': 'Receive in-app notifications about your appointments',
  'notification.settings.inAppReminders': 'In-app reminders',
  'notification.settings.receiveInAppReminders': 'Receive in-app appointment reminders',
  'notification.settings.wantToGetEmailNotifications': 'I want to get an email notifications',
  'notification.settings.emailCommunicationNotifications': 'Email communication notifications',
  'notification.settings.emailNotificationsAboutChatMessages':
    'Receive email notifications about new chat messages or files',
  'notification.settings.emailAppointmentNotifications': 'Email appointment notifications',
  'notification.settings.emailNotificationsAboutAppointments': 'Receive email notifications about your appointments',
  'notification.settings.emailReminders': 'Email reminders',
  'notification.settings.receiveEmailAppointmentReminders': 'Receive email appointment reminders',
};

export default locales;

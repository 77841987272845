import { APP_NAME } from 'constants/app';

const locales = {
  'workWithUs.banner.title': 'Reach more new patients',
  'workWithUs.joinAirPMD': `Join ${APP_NAME}`,
  'workWithUs.banner.description': `${APP_NAME} is a website that connects providers with new patients.`,
  'workWithUs.joinTeam.title': 'Join a team that’s changing healthcare',
  'workWithUs.joinTeam.description': "If you like making things that make a difference, we'd love to hear from you!",
  'workWithUs.joinTeam.registerAsProvider': 'Register as provider and our team will contact you shortly.',
  'workWithUs.patientManagement.title': 'Patient management',
  'workWithUs.patientManagement.description':
    'All your patients, meeting notes, health records, and prescriptions are safely stored in one place.',
  'workWithUs.liveChat.title': 'Live chat',
  'workWithUs.liveChat.description':
    'Encrypted live chat with the file-sharing option available before and during the consultation.',
  'workWithUs.videoConferencing.title': 'Video conferencing',
  'workWithUs.videoConferencing.description':
    'Have secure and high-quality video meetings one-to-one with your patients, with a screen-sharing option.',
  'workWithUs.advancedScheduling.title': 'Advanced scheduling',
  'workWithUs.advancedScheduling.description': ' Make the most out of your time and let the work fit your schedule.',
  'workWithUs.treatPatientsAndEarnMoneyOnline.title': 'Treat patients and earn money online!',
  'workWithUs.treatPatientsAndEarnMoneyOnline.description': 'Fast and easy way to get money earned on our platform.',
  'workWithUs.startGettingAppointments.title': 'Start getting appointments',
  'workWithUs.startGettingAppointments.description': `Almost half of ${APP_NAME} appointments occur within 72 hours of booking.`,
  'workWithUs.setUpYourSchedule.title': 'Set up your schedule',
  'workWithUs.setUpYourSchedule.description':
    'Fill last-minute openings in your schedule, reach patients in your area looking for a new provider',
  'workWithUs.become_practitioner.title': `Become a ${APP_NAME} practitioner`,
  'workWithUs.become_practitioner.description':
    'Millions of patients visit our website and app every month to search for a local provider.',
};

export default locales;

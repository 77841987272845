const locales = {
  'onboarding.fillPersonalInformation': 'Fill in personal information',
  'onboarding.fillContactInformation': 'Fill in contact information',
  'onboarding.fillHealthProfile': 'Fill in patient health profile',
  'onboarding.fillMedicalLicence': 'Fill in your medical licence',
  'onboarding.specialityWorkingHours': 'Speciality and working hours',
  'onboarding.additionalInformation': 'Additional info',
  'onboarding.cancellationScenario.tab': 'Cancellation scenario',
  'onboarding.rescheduleScenario.tab': 'Reschedule scenario',
  'onboarding.signature.tab': 'Signature',
  'onboarding.cancellationScenario': 'Cancellation scenario',
  'onboarding.patient.thankYouForJoiningUs': 'Thank you for joining us! Your patient account is created!',
  'onboarding.startBookingYourAppointmentRightNow': 'Start booking your appointment right now!',
  'onboarding.startBookingAppointment': 'Start Booking an Appointment',
  'onboarding.pleaseProvideYourMedicalLicense':
    'Please provide your medical license and state where you can provide medical consultation',
  'shared.addYourConsultationPrices': 'Add your consultation prices (per 1 hour)',
  'shared.forPrimaryCareDoctors': 'For Primary Care Doctors the default appointment duration will be 15 min',
  'onboarding.donWorryYouWillCustomizeAvailabilityLater':
    'Don’t worry! You’ll be able to further customize your availability later on.',
  'onboarding.pleaseInvestigateOurPlatformDuringLicenseValidation':
    'Please investigate our platform during license validation.',
  'onboarding.thankForJoiningYourAccountIsAlmostCreated':
    'Thank you for joining us! Your providers account is almost created! Our Administrators are already checking your medical information it may take a bit of time.',
  'onboarding.inCaseYouWillIndicateYourCancellation':
    'In case you will not indicate your cancellation scenario we will keep the default one',
  'onboarding.72Hours': '72 hours or earlier',
  'onboarding.48Hours': '48 hours',
  'onboarding.24Hours': '24 hours before',
  'onboarding.1Hour': '1 hour before',
  'onboarding.ofIndicatedPrice': '% of the indicated price',
  'onboarding.signature': 'Signature',
  'onboarding.providerShouldAddSignature': `The provider should be able to see the “Add signature” button on the Onboarding (or on the Manage Profile screen)`,
};

export default locales;

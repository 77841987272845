import { APP_NAME } from 'constants/app';

const locales = {
  'faq.title': 'FAQ',
  'faq.banner.title': 'How can we help you?',
  'faq.banner.description': `Here you will find the answer to the most frequently asked questions that come in to ${APP_NAME} support.`,

  'faq.generalQuestions.tab': 'General Questions',
  'faq.forPatients.tab': 'For Patients',
  'faq.forDoctors.tab': 'For Doctors',

  'faq.aboutUs': `About ${APP_NAME}`,
  'faq.whenCanBeUsed': `When can ${APP_NAME} be used?`,
  'faq.costAndPayment': 'Cost and payment',
  'faq.support': `${APP_NAME} Support`,

  'faq.article_1.header_1': `How does ${APP_NAME} work?`,
  'faq.article_1.header_2': `How do I log in and out of the ${APP_NAME}?`,
  'faq.article_1.description_2.logIn': 'Log in:',
  'faq.article_1.description_2.logIn.list.item_1': `Download the KRY app on your mobile device from either App Store, which requires iOS (v.10 or later) or Google Play, which requires Android (v.5.0 or later).`,
  'faq.article_1.description_2.logIn.list.item_2': `Click Sign in and identify yourself with your Mobile BankID.`,
  'faq.article_1.description_2.logIn.list.item_3': `If it's your first time using the app you need to register yourself as a user by entering a phone number and email address and accept the Terms of Service`,
  'faq.article_1.description_2.logIn.text': `Keep in mind that you can only use your own Mobile BankID in order to use the app for yourself or your child who doesn't have a Mobile BankID. It is not possible to book a meeting for someone else since KRY requires that every patient can identify themselves through Mobile BankID.`,
  'faq.article_1.description_2.logOut': 'Log out:',
  'faq.article_1.description_2.logOut.list_1.item_1': `Enter the Me tab at the bottom right of the KRY app.`,
  'faq.article_1.description_2.logOut.list_1.item_2': `Click on Log out`,
  'faq.article_1.description_2.logOut.text': `If you have an older version of the app, follow these steps`,
  'faq.article_1.description_2.logOut.list_2.item_1': `Enter the More tab at the bottom right of the KRY app.`,
  'faq.article_1.description_2.logOut.list_2.item_2': `Click on Settings and then Log out`,

  'faq.article_1.header_3': `Who are the doctors that work at ${APP_NAME}?`,
  'faq.article_1.header_4': `How is ${APP_NAME} different from the health advice and information service 1177?`,

  'faq.article_2.header_1': `Which symptoms can ${APP_NAME} help with?`,
  'faq.article_2.header_2': `Is ${APP_NAME} a suitable healthcare provider for all medical problems?`,
  'faq.article_2.header_3': `Can I use ${APP_NAME} abroad or in different locations?`,
  'faq.article_2.header_4': `Can I book a meeting on behalf of my child?`,

  'faq.article_3.header_1': `How much does it cost to use Air-pmd?`,
  'faq.article_3.header_2': `How much does it cost for children?`,
  'faq.article_3.header_3': `Do I get a receipt for my meeting?`,
  'faq.article_3.header_4': `Are high cost protection cards applicable?`,
  'faq.article_3.header_5': `How do I get a factura after topping up my balance?`,

  'faq.article_4.header_1': `Contact information for ${APP_NAME} Support`,
  'faq.article_4.header_2': `Questions about or seeking medical advice`,
  'faq.article_4.header_3': `Who should I contact for possbile complaints?`,

  'faq.article.notImplemented': `Not implemented`,
};

export default locales;

const locales = {
  'checklist.user_account': 'User account',
  'checklist.user_profile': 'User profile',
  'checklist.payout_method': 'Payout method',
  'checklist.state_licenses': 'State licenses',
  'checklist.working_hours': 'Working hours',
  'checklist.accountStatus': 'Account status',
};

export default locales;

import { APP_NAME } from 'constants/app';

const locales = {
  'static.seeAnswersToQuestions': 'See answers to the most common questions we get asked. See {faq}.',
  'static.faq': 'FAQ',
  'static.ourCareTeamIsHereToSupport': 'Have questions? Our care team is here to {support_you_24_7}.',
  'static.support_you_24_7': 'support you 24/7',
  'static.contactUs': 'Contact us',
  'static.whetherItsPositiveOrNot': "Whether it's positive, or not, we want to hear from you. Email {email}",
  'static.ifYouHaveQuestions': 'If you have questions, concerns or suggestions feel free to contact us at:',
  'static.getInTouch': 'Get in touch',
  'static.weLoveToAnswerAnyQuestions': "We'd love to answer any questions you may have.",
  'static.customerService': 'Customer service',
  'static.careSupport': '24/7 care support',
  'static.haveQuestion': 'Have a question?',

  'static.aboutUs.title': 'About us',
  'static.aboutUs.weAre.title': `We are ${APP_NAME}`,
  'static.aboutUs.weAre.description': `An online medical platform for everyone that provides an easy and convenient way to get quality treatment throughout the United States.`,
  'static.aboutUs.whoWeAre.title': 'Who we are',
  'static.aboutUs.whoWeAre.subTitle': 'To live their healthiest lives by transforming the healthcare experience',
  'static.aboutUs.whoWeAre.description_1': `${APP_NAME} is in starting virtual care— offering a way to connect and the power to improve health for all people`,
  'static.aboutUs.whoWeAre.description_2': `We are a ${APP_NAME} team of professionals that wanted to make medicine accessible, affordable, and convenient for everyone. We want to help people get high-quality, well-timed treatment from certified doctors at a reasonable cost without having to leave their homes.`,
  'static.aboutUs.ourMission.title': 'Our mission',
  'static.aboutUs.ourMission.subTitle': 'The medical sphere should be comfortable for patient and doctor',
  'static.aboutUs.ourMission.list.item_1': `More of the time patient spent to a receive an insurance, then wait on hold, then waiting for the visit and receiving a surprise bill.`,
  'static.aboutUs.ourMission.list.item_2': `Doctors spent their time sitting in their private cabinets and waiting and waiting for patients to come - but what if patients don't come on that day? A lot of valuable time just wasted
    {breakline}{breakline}
    We are giving power to our patients and doctors! We make healthcare approachable!`,
  'static.aboutUs.meetOurLeadership.title': 'Meet our leadership',
  'static.aboutUs.meetOurLeadership.subTitle': 'Adam Douglas',
  'static.aboutUs.meetOurLeadership.text': '“We have created a Simple and accessible system for everyone”',
  'static.aboutUs.meetOurLeadership.list.item_1': `With ${APP_NAME}, patients can choose highly specialized providers, providers who can see them soon,providers who will provide hight quality medical help`,
  'static.aboutUs.meetOurLeadership.list.item_2': `${APP_NAME} helps doctors easily find the patients they want to serve, helping grow medical careers and reach extra money`,
  'static.aboutUs.ourValues.title': 'Our values',
  'static.aboutUs.ourValues.description': `${APP_NAME} way of doing things`,
  'static.aboutUs.care.title': 'Care',
  'static.aboutUs.care.description': `At ${APP_NAME}, we always put patients’ health, comfort, and time first.`,
  'static.aboutUs.accessibility.title': 'Accessibility',
  'static.aboutUs.accessibility.description': `We bring good care to everyone, wherever they are, whenever they need it.`,
  'static.aboutUs.expertise.title': 'Expertise',
  'static.aboutUs.expertise.description': `We serve with competence, integrity, and take personal ownership of our work.`,
  'static.aboutUs.empowering.title': 'Empowering',
  'static.aboutUs.empowering.description': `We bring convenience, outcomes, and value to patients and doctors by letting them focus on what’s important.`,
  'static.aboutUs.vision.title': 'Vision',
  'static.aboutUs.vision.description': `We are building the future of healthcare by making it easy to access and convenient to use with technology.`,
};

export default locales;
